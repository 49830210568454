import { FullScreenPage } from "components/PageContainer";
import React, { useState } from "react";
import { Button } from "tenaissance/components/Button";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import {
  ProductAndRateContext,
  ProductAndRateInfo,
} from "./ProductAndRateContext";

export const RateCardCreateV2: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [products, setProducts] = useState<ProductAndRateInfo[]>([]);

  const steps = [
    {
      title: "Step 1",
      content: <Step1 />,
    },
    {
      title: "Step 2",
      content: <Step2 />,
    },
    {
      title: "Step 3",
      content: <Step3 />,
    },
  ];

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;

  return (
    <FullScreenPage title="hi">
      <div className="ml-[100px] mt-[100px] w-[300px] border-8 border-solid p-[20px]">
        <div className="mb-[10px] w-full">
          <b>Create a rate card</b>
        </div>

        {/* Display the current step */}
        <div className="border-8 border-solid p-[20px]">
          <ProductAndRateContext.Provider value={{ products, setProducts }}>
            <div>{steps[currentStep].content}</div>
          </ProductAndRateContext.Provider>
        </div>

        {/* Buttons to navigate back and forth */}
        <div className="mt-[20px] w-full text-right">
          {!isFirstStep && (
            <Button
              onClick={() => setCurrentStep(currentStep - 1)}
              text="Back"
              theme="secondary"
              className="mr-[10px]"
            />
          )}
          <Button
            onClick={() => {
              // TODO: if there is no dimensional pricing,
              // skip to the last step
              if (isLastStep) {
                console.log("Saving...");
                return;
              }
              setCurrentStep(currentStep + 1);
            }}
            text={isLastStep ? "Save" : "Next"}
            theme="primary"
          />
        </div>
      </div>
    </FullScreenPage>
  );
};
