import { z } from "zod";
import { v4 as uuid } from "uuid";
import { dayjs } from "lib/dayjs";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";
import { FormController } from "lib/FormController";
import {
  AmountDistributionType,
  CommitType,
  ContractUsageInvoiceBillingCycleAnchorEnum,
  ContractUsageInvoiceScheduleFrequencyEnum,
  EnvironmentTypeEnum_Enum,
  ExternalCommitType,
  RecurringScheduleFrequency,
  TransitionTypeEnum,
} from "types/generated-graphql/__types__";

// ID that isn't sent to the backend, only there so we can reference a
// specific part of the contract data structure for editting in the UI
const EDIT_ONLY_UUID = z
  .string()
  .uuid()
  .default(() => uuid());

// standard schema for optional name fields, 1 => 128 characters, defaults to undefined
const OPTIONAL_NAME = z
  .string()
  .max(128, "Name can not be longer than 128 characters")
  .optional()
  .transform(
    (v) =>
      /* convert empty strings to undefined */
      v || undefined,
  );

export namespace Refine {
  export function join<V>(
    ...refinements: Array<(v: V, ctx: z.RefinementCtx) => void>
  ) {
    return (value: V, ctx: z.RefinementCtx) => {
      for (const refinement of refinements) {
        refinement(value, ctx);
      }
    };
  }

  export function startingAtAndEndingBefore<
    V extends { startingAt: string; endingBefore?: string },
  >(obj: V, ctx: z.RefinementCtx) {
    if (
      obj.endingBefore &&
      new Date(obj.startingAt).getTime() >= new Date(obj.endingBefore).getTime()
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endingBefore"],
        message: "End date must be after starting date",
        fatal: true,
      });
    }
  }

  export function startDateAndEndDate(
    obj: { startDate: string; endDate?: string },
    ctx: z.RefinementCtx,
  ) {
    if (
      obj.endDate &&
      new Date(obj.startDate).getTime() >= new Date(obj.endDate).getTime()
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endDate"],
        message: "End date must be after starting date",
        fatal: true,
      });
    }
  }

  export function isUtcMidnight(val: string, ctx: z.RefinementCtx) {
    const d = dayjs.utc(val);
    if (
      d.hour() !== 0 ||
      d.minute() !== 0 ||
      d.second() !== 0 ||
      d.millisecond() !== 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Time in datetime must be UTC midnight",
        fatal: true,
      });
    }
  }

  export function ifNotOneThenTheOther<V>(one: keyof V, other: keyof V) {
    return (obj: V, ctx: z.RefinementCtx) => {
      if (!obj[one] && !obj[other]) {
        ctx.addIssue({
          path: [String(other)],
          code: z.ZodIssueCode.invalid_type,
          expected: "string",
          received: "undefined",
          message: "Required",
          fatal: true,
        });
      }
    };
  }

  export function ifOneThenTheOther<V>(one: keyof V, other: keyof V) {
    return (obj: V, ctx: z.RefinementCtx) => {
      if (obj[one] && !obj[other]) {
        ctx.addIssue({
          path: [String(other)],
          code: z.ZodIssueCode.invalid_type,
          expected: "string",
          received: "undefined",
          message: "Required",
          fatal: true,
        });
      }

      if (!obj[one] && obj[other]) {
        ctx.addIssue({
          path: [String(one)],
          code: z.ZodIssueCode.invalid_type,
          expected: "string",
          received: "undefined",
          message: "Required",
          fatal: true,
        });
      }
    };
  }
}

export namespace Schema {
  export const CreditType = z.object({
    id: z.string().uuid(),
    name: z.string(),
    client_id: z.string().uuid().or(z.null()),
    environment_type: z
      .enum([
        EnvironmentTypeEnum_Enum.Production,
        EnvironmentTypeEnum_Enum.Qa,
        EnvironmentTypeEnum_Enum.Sandbox,
        EnvironmentTypeEnum_Enum.Staging,
        EnvironmentTypeEnum_Enum.Uat,
      ])
      .or(z.null()),
  });

  export const FlatRate = z.object({
    type: z.literal("flat"),
    unitPrice: z.number().nonnegative(),
    creditType: CreditType,
  });

  export const SubscriptionRate = z.object({
    type: z.literal("subscription"),
    unitPrice: z.number().nonnegative(),
    quantity: z.number().nonnegative().default(1),
    isProrated: z.boolean().default(true),
    creditType: CreditType,
  });

  export const PercentageRate = z.object({
    type: z.literal("percentage"),
    fraction: z.number().positive(),
  });

  export const TierForm = z
    .object({
      lastUnit: z.number().nonnegative().optional(),
      unitPrice: z.number().nonnegative(),
      prevLastUnit: z.number().nonnegative().optional(),
      isLastTier: z.boolean(),
    })
    .superRefine((tier, ctx) => {
      if (
        tier.lastUnit !== undefined &&
        tier.prevLastUnit !== undefined &&
        tier.lastUnit <= tier.prevLastUnit
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Last unit must be greater than previous last unit",
          path: ["lastUnit"],
          fatal: true,
        });
      }
      if (!tier.isLastTier && tier.lastUnit === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Only the last tier can be unbounded",
          path: ["lastUnit"],
          fatal: true,
        });
      }
    });

  export const Tier = z.object({
    lastUnit: z.number().nonnegative().optional(),
    unitPrice: z.number().nonnegative(),
  });

  export const TieredRate = z.object({
    type: z.literal("tiered"),
    tiers: z.array(Tier),
    creditType: CreditType,
  });

  export const OverwriteOverride = z.object({
    type: z.literal("overwrite"),
    newRate: z.union([FlatRate, PercentageRate, SubscriptionRate, TieredRate]),
  });

  export const MultiplierOverride = z.object({
    type: z.literal("multiplier"),
    multiplier: z.number(),
    priority: z.number().positive().optional(),
  });

  export const ENTITLED_ENUM = z.enum(["inherit", "enable", "disable"]);
  export function parseEntitled(value: z.infer<typeof ENTITLED_ENUM>) {
    return value === "inherit" ? undefined : value === "enable";
  }

  export const Override = z
    .object({
      id: EDIT_ONLY_UUID,
      startingAt: z.string().datetime(),
      endingBefore: z.string().datetime().optional(),
      entitled: ENTITLED_ENUM.default("inherit"),
      rate: z.union([OverwriteOverride, MultiplierOverride]).optional(),
      productId: z.string().uuid().optional(),
      tags: z.array(z.string()).optional(),
      pricingModel: z.enum(["flat usage", "tiered usage"]).optional(),
      type: z
        .union([z.literal("overwrite"), z.literal("multiplier")])
        .optional(),
    })
    .superRefine(
      Refine.join(Refine.startingAtAndEndingBefore, (data, ctx) => {
        if (!data.productId && !data.tags?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            received: "undefined",
            expected: "string",
            path: ["productId"],
            message: "Either a product or product tags must be selected",
            fatal: true,
          });
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            received: "undefined",
            expected: "string",
            path: ["tags"],
            message: "Either a product or product tags must be selected",
            fatal: true,
          });
        }
      }),
    );

  export const FixedScheduleItem = z.object({
    id: EDIT_ONLY_UUID,
    date: z.string().datetime(),
    unitPrice: z.number().nonnegative(),
    quantity: z.number().nonnegative().default(1),
  });
  export const FixedSchedule = z.object({
    type: z.literal("fixed"),
    items: z.array(FixedScheduleItem),
  });

  export const RECURRING_FREQUENCY = [
    RecurringScheduleFrequency.Monthly,
    RecurringScheduleFrequency.Quarterly,
    RecurringScheduleFrequency.SemiAnnual,
    RecurringScheduleFrequency.Annual,
  ] as const satisfies readonly RecurringSchedule.ScheduleSpec["frequency"][];

  export const RECURRING_DISTRIBUTION = [
    AmountDistributionType.Divided,
    AmountDistributionType.DividedRounded,
    AmountDistributionType.Each,
  ] as const satisfies readonly RecurringSchedule.ScheduleSpec["amountDistribution"][];

  export const RecurringSchedule = z
    .object({
      type: z.literal("recurring"),
      amountDistribution: z.enum(RECURRING_DISTRIBUTION),
      startDate: z.string().datetime(),
      endDate: z.string().datetime(),
      frequency: z.enum(RECURRING_FREQUENCY),
      quantity: z.number().nonnegative().default(1),
      unitPrice: z.number().nonnegative(),
    })
    .superRefine(Refine.startDateAndEndDate);

  export const BILLING_SCHEDULE_FREQUENCY = [
    "none",
    "one",
    "custom",
    ...Schema.RECURRING_FREQUENCY,
  ] as const;
  export const BillingSchedule = z.union([FixedSchedule, RecurringSchedule]);

  export const RECURRING_FREQUENCY_MAP: Record<
    Types.RecurringFrequency,
    RecurringScheduleFrequency
  > = {
    MONTHLY: RecurringScheduleFrequency.Monthly,
    QUARTERLY: RecurringScheduleFrequency.Quarterly,
    SEMI_ANNUAL: RecurringScheduleFrequency.SemiAnnual,
    ANNUAL: RecurringScheduleFrequency.Annual,
  };

  export const PrepaidCommitAccessScheduleItem = z.object({
    id: EDIT_ONLY_UUID,
    amount: z.number().gt(0),
    date: z.string().datetime(),
    endDate: z.string().datetime(),
  });

  export const PrepaidCommit = z
    .object({
      type: z.literal(CommitType.Prepaid),
      priority: z.number().default(100),
      level: z.union([z.literal("contract"), z.literal("customer")]),
      externalType: z.enum([
        ExternalCommitType.Commit,
        ExternalCommitType.Credit,
      ]),
      accessSchedule: z.array(PrepaidCommitAccessScheduleItem).min(1),
      accessScheduleCreditTypeId: z.string(),

      // support for shared billing schedule component
      billingScheduleFrequency: z
        .enum(BILLING_SCHEDULE_FREQUENCY)
        .default("one"),
      billingSchedule: BillingSchedule,

      rolloverFraction: z.number().min(0).max(100).optional(),

      // for customer commits
      invoiceContractId: z.string().uuid().optional(),
    })
    .superRefine((obj, ctx) => {
      if (
        obj.level === "customer" &&
        obj.externalType !== ExternalCommitType.Credit &&
        obj.billingScheduleFrequency !== "none" &&
        !obj.invoiceContractId
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          received: "undefined",
          expected: "string",
          path: ["invoiceContractId"],
          message: "Required",
          fatal: true,
        });
      }
    });

  export const PostpaidCommitAccessScheduleItem = z.object({
    id: EDIT_ONLY_UUID,
    amount: z.number().gt(0),
    date: z.string().datetime(),
    endDate: z.string().datetime(),
  });

  export const PostpaidCommitBillingScheduleItem = z.object({
    id: EDIT_ONLY_UUID,
    date: z.string().datetime(),
    unitPrice: z.number().gt(0),
    quantity: z.number().gt(0).default(1),
  });

  export const PostpaidCommit = z
    .object({
      type: z.literal(CommitType.Postpaid),
      level: z.union([z.literal("contract"), z.literal("customer")]),
      accessSchedule: z.array(PostpaidCommitAccessScheduleItem).min(1).max(1),
      billingSchedule: z.array(PostpaidCommitBillingScheduleItem).min(1).max(1),
      rolloverFraction: z.number().min(0).max(100).optional(),
      priority: z.number().default(100),
      // for customer commits
      invoiceContractId: z.string().uuid().optional(),
    })
    .superRefine((obj, ctx) => {
      // amount in access schedule item must match unit price * quantity in billing schedule item
      const accessAmount = obj.accessSchedule?.[0]?.amount;
      const billingAmount =
        obj.billingSchedule?.[0]?.unitPrice *
        obj.billingSchedule?.[0]?.quantity;
      if (accessAmount !== billingAmount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["accessSchedule", 0, "amount"],
          message: "Access schedule amount must match billing schedule amount",
          fatal: true,
        });
      }
      if (obj.level === "customer" && !obj.invoiceContractId) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          received: "undefined",
          expected: "string",
          path: ["invoiceContractId"],
          message: "Required",
          fatal: true,
        });
      }
    });

  export const CommitFlyoverRoot = z.object({
    id: EDIT_ONLY_UUID,
    name: OPTIONAL_NAME,
    description: z.string().optional(),
    productId: z.string().uuid(),
    applicableProductIds: z.array(z.string().uuid()).optional(),
    applicableProductTags: z.array(z.string()).optional(),
    applicableContractIds: z.array(z.string().uuid()).optional(),
    commit: z.union([PrepaidCommit, PostpaidCommit]),
    type: z
      .enum([CommitType.Prepaid, CommitType.Postpaid])
      .default(CommitType.Prepaid),
    netsuiteSalesOrderId: z.string().optional(),
  });

  export const ScheduledCharge = z.object({
    id: EDIT_ONLY_UUID,
    productId: z.string().uuid(),

    // support for shared billing schedule component
    billingScheduleFrequency: z.enum(BILLING_SCHEDULE_FREQUENCY).default("one"),
    billingSchedule: BillingSchedule,
  });

  export const Discount = z.object({
    id: EDIT_ONLY_UUID,
    productId: z.string().uuid(),

    // support for shared billing schedule component
    billingScheduleFrequency: z.enum(BILLING_SCHEDULE_FREQUENCY).default("one"),
    billingSchedule: BillingSchedule,
  });

  export const ProService = z.object({
    id: EDIT_ONLY_UUID,
    description: z.string().optional(),
    productId: z.string().uuid(),
    netSuiteSalesOrderId: z.string().optional(),
    unitPrice: z.number().nonnegative(),
    quantity: z.number().nonnegative(),
    maxAmount: z.number().nonnegative(),
  });

  export const AwsRoyaltyMetadata = z.object({
    type: z.literal("aws"),
    awsAccountNumber: z.string().optional(),
    awsPayerReferenceId: z.string().optional(),
    awsOfferId: z.string().optional(),
  });

  export const GcpRoyaltyMetadata = z.object({
    type: z.literal("gcp"),
    gcpAccountId: z.string().optional(),
    gcpOfferId: z.string().optional(),
  });

  export const ResellerRoyalty = z
    .object({
      id: EDIT_ONLY_UUID,
      type: z
        .enum(["aws", "gcp", "awsProService", "gcpProService"])
        .default("aws"),
      netSuiteResellerId: z.string(),
      percentage: z.number().nonnegative().max(100),
      applicableProductIds: z.array(z.string().uuid()).optional(),
      applicableProductTags: z.array(z.string()).optional(),
      startingAt: z.string().datetime().superRefine(Refine.isUtcMidnight),
      endingBefore: z
        .string()
        .datetime()
        .superRefine(Refine.isUtcMidnight)
        .optional(),
      metadata: z.union([AwsRoyaltyMetadata, GcpRoyaltyMetadata]).optional(),
    })
    .superRefine(Refine.startingAtAndEndingBefore);

  const CreateSharedInput = z.object({
    netsuiteSalesOrderId: z
      .string()
      .optional()
      .transform((v) => v || undefined),
    salesforceOpportunityId: z
      .string()
      .optional()
      .transform((v) => v || undefined)
      .refine((val) => {
        if (!val) return true;

        return (
          val.startsWith("006") && (val.length === 15 || val.length === 18)
        );
      }, "Salesforce opportunity ID must be a valid 15 or 18 character ID starting with 006"),
    commits: z.array(CommitFlyoverRoot).optional(),
    credits: z.array(CommitFlyoverRoot).optional(),
    overrides: z.array(Override).optional(),
    multiplierOverridePrioritization: z
      .enum(["LOWEST_MULTIPLIER", "EXPLICIT"])
      .optional(),
    scheduledCharges: z.array(ScheduledCharge).optional(),
    discounts: z.array(Discount).optional(),
    resellerRoyalties: z
      .array(ResellerRoyalty)
      .max(2) // a contract can have at most 1 aws and 1 gcp royalty
      .optional(),
    proServices: z.array(ProService).optional(),
  });

  export const CreateAmendmentInput = CreateSharedInput.extend({
    effectiveAt: z.string().datetime().superRefine(Refine.isUtcMidnight),
  });

  export const CreateContractInput = CreateSharedInput.extend({
    name: OPTIONAL_NAME,
    startingAt: z
      .string()
      .datetime()
      .default(() => dayjs.utc().startOf("day").toISOString())
      .superRefine(Refine.isUtcMidnight),
    endingBefore: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
    rateCardId: z.string().uuid().optional(),
    netPaymentTermsDays: z.number().optional(),
    usageFilterGroupKey: z.string().optional(),
    usageFilterGroupValues: z.string().optional(),
    transitionType: z
      .enum([TransitionTypeEnum.Renewal, TransitionTypeEnum.Supersede])
      .optional(),
    transitionPreviousContractId: z.string().uuid().optional(),
    usageInvoiceFrequency: z
      .enum([
        ContractUsageInvoiceScheduleFrequencyEnum.Monthly,
        ContractUsageInvoiceScheduleFrequencyEnum.Quarterly,
      ])
      .default(ContractUsageInvoiceScheduleFrequencyEnum.Monthly),
    billingCycleAnchor: z
      .enum([
        ContractUsageInvoiceBillingCycleAnchorEnum.FirstOfMonth,
        ContractUsageInvoiceBillingCycleAnchorEnum.ContractStart,
        ContractUsageInvoiceBillingCycleAnchorEnum.CustomDate,
      ])
      .default(ContractUsageInvoiceBillingCycleAnchorEnum.FirstOfMonth),
    billingCycleAnchorDate: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
  }).superRefine(
    Refine.join(
      Refine.startingAtAndEndingBefore,
      Refine.ifNotOneThenTheOther("rateCardId", "name"),
      Refine.ifOneThenTheOther(
        "transitionType",
        "transitionPreviousContractId",
      ),
      // if billingCycleAnchor is set to custom, billingCycleAnchorDate is required
      (data, ctx) => {
        if (
          data.billingCycleAnchor ===
            ContractUsageInvoiceBillingCycleAnchorEnum.CustomDate &&
          !data.billingCycleAnchorDate
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            received: "undefined",
            expected: "string",
            path: ["billingCycleAnchorDate"],
            message: "Required with custom billing date",
            fatal: true,
          });
        }
      },
      // if using custom date, it  must be at or before the contract start date
      (data, ctx) => {
        if (
          data.billingCycleAnchor ===
            ContractUsageInvoiceBillingCycleAnchorEnum.CustomDate &&
          data.billingCycleAnchorDate &&
          new Date(data.billingCycleAnchorDate).getTime() >
            new Date(data.startingAt).getTime()
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["billingCycleAnchorDate"],
            message: "Must be at or before the contract start date",
            fatal: true,
          });
        }
      },
    ),
  );

  export const EditContractEndDateInput = z.object({
    endingBefore: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
  });

  export namespace Types {
    export type AdditionalTermType =
      | "scheduled_charge"
      | "reseller_royalty"
      | "discount"
      | "pro_service";
    export type RecurringFrequency = (typeof RECURRING_FREQUENCY)[number];
    export type RecurringDistribution = (typeof RECURRING_DISTRIBUTION)[number];
    export type RecurringSchedule = z.infer<typeof RecurringSchedule>;
    export type Override = z.infer<typeof Override>;
    export type PrepaidCommit = z.infer<typeof PrepaidCommit>;
    export type PostpaidCommit = z.infer<typeof PostpaidCommit>;
    export type ScheduledCharge = z.infer<typeof ScheduledCharge>;
    export type Discount = z.infer<typeof Discount>;
    export type ProService = z.infer<typeof ProService>;
    export type BillingSchedule = z.infer<typeof BillingSchedule>;
    export type FixedScheduleItem = z.infer<typeof FixedScheduleItem>;
    export type BillingScheduleFrequency =
      (typeof BILLING_SCHEDULE_FREQUENCY)[number];
    export type AwsRoyalty = z.infer<typeof AwsRoyaltyMetadata>;
    export type GcpRoyalty = z.infer<typeof GcpRoyaltyMetadata>;
    export type ResellerRoyalty = z.infer<typeof ResellerRoyalty>;
    export type CommitFlyoverRoot = z.infer<typeof CommitFlyoverRoot>;

    export type CreateContractInput = z.infer<typeof CreateContractInput>;
    export type CreateAmendmentInput = z.infer<typeof CreateAmendmentInput>;
    export type CreateSharedInput = z.infer<typeof CreateSharedInput>;
    export type CreateSharedCtrl = FormController<
      (typeof CreateSharedInput)["shape"]
    >;
    export type OverwriteOverride = z.infer<typeof OverwriteOverride>;
    export type MultiplierOverride = z.infer<typeof MultiplierOverride>;
    export type Tier = z.infer<typeof Tier>;
  }
}
