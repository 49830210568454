import React, { useState } from "react";

import {
  GetActiveMarketplaceContractsQueryResult,
  GetClientConfigQueryResult,
  useGetActiveMarketplaceContractsQuery,
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "./queries.graphql";
import {
  BillingProviderEnum_Enum,
  ClientConfig,
  ClientConfigKeyEnum_Enum,
} from "types/generated-graphql/__types__";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useFeatureFlag } from "../../../../../../lib/launchdarkly";
import AWSMarketplaceSettingsModal from "../../components/AWSMarketplaceSettingsModal";
import AzureMarketplaceSettingsModal from "../../components/AzureMarketplaceSettingsModal";
import {
  AWSMarketplaceCustomerSettings,
  AzureMarketplaceCustomerSettings,
  CustomerIntegrationSettingsInfo,
  FirstClassIntegrations,
  getCustomerIntegrationCardVisibility,
  MultiBPCustomerSettings,
  NetSuiteConnectionCustomerSettings,
  parseIntegrationCustomerSettings,
  SalesforceConnectionCustomerSettings,
  StripeCustomerSettings,
} from "../../../../../../lib/billingProvider/billingProviderSettings";
import { Column, Table } from "../../../../../../tenaissance/components/Table";
import { SectionHeader } from "../../../../../../tenaissance/components/SectionHeader";
import {
  IntegrationDetailProps,
  IntegrationDetails,
} from "../../../../../GeneralSettings/components/IntegrationsTable/IntegrationDetails";
import { renderDate } from "lib/time";
import { AzureIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/AzureIntegrationCard";
import { AwsIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/AwsIntegrationCard";
import { StripeIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/StripeIntegrationCard";
import StripeSettingsModal from "../../components/StripeSettingsModal";
import { NetSuiteConnectionModal } from "../../components/NetSuiteConnectionModal";
import { SalesforceConnectionModal } from "../../components/SalesforceConnectionModal";
import { BaseIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/BaseIntegrationCard";
import { ReactComponent as NetSuiteLogo } from "tenaissance/icons/netsuite.svg";
import { SalesforceIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/SalesforceIntegrationCard";
import {
  Dropdown,
  DropdownHeader,
  DropdownItem,
} from "../../../../../../tenaissance/components/Dropdown";
import { useNavigate } from "../../../../../../lib/useNavigate";
import { useUIMode } from "../../../../../../lib/useUIMode";

type BillingProviderSectionProps = {
  customerID: string;
  classNames?: string;
};

export function parseClientGracePeriodHours(
  clientConfig: GetClientConfigQueryResult["data"],
) {
  const getConfigKey = (
    clientConfig: Partial<ClientConfig>[] | undefined,
    key: ClientConfigKeyEnum_Enum,
  ) =>
    clientConfig?.length
      ? clientConfig.find((c) => c.key === key)?.value
      : undefined;
  // note: for now we determine if delta stream is enabled based on client
  // config (across billingProviders), not client's delivery method (per billingProvider).
  // this works b/c only CFLT is using it & we'll configure all billingProviders at once.
  // future: modify list_delivery_method resolver to target a specific clients
  return parseInt(
    getConfigKey(
      clientConfig?.ClientConfig,
      ClientConfigKeyEnum_Enum.GracePeriodHours,
    ) || "24",
  );
}

type ParsedContract = {
  id: string;
  name: string | null;
  customer_billing_provider_configuration: { id: string } | null;
};
type ParsedCustomerPlan = {
  id: string;
  name: string;
  billing_provider: BillingProviderEnum_Enum | null;
};
type ParsedContractsAndPlan = {
  contracts?: ParsedContract[];
  plan?: ParsedCustomerPlan;
};

export function parseActiveContractsAndPlan(
  contractsAndPlans: GetActiveMarketplaceContractsQueryResult["data"],
  billingProvider: BillingProviderEnum_Enum,
): ParsedContractsAndPlan {
  const customerPlan =
    contractsAndPlans?.active_customer_plan_billing_provider
      ?.CustomerPlans?.[0];
  return {
    // the query already filters contracts by BP
    contracts: contractsAndPlans?.contracts_page.contracts,
    // The query does not filter plans by BP, but retrieves the singular active
    // plan. We can assume the active plan has the same billing provider as that
    // which is attached to the current BillingProviderCustomer configuration
    plan: customerPlan?.Plan && {
      id: customerPlan?.id,
      name: customerPlan?.Plan?.name,
      billing_provider: billingProvider,
    },
  };
}

export const BillingProviderSection: React.FC<BillingProviderSectionProps> = ({
  customerID,
  classNames,
}) => {
  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
    useState(false);
  const [
    azureMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
  ] = useState(false);
  const [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false);
  const [netsuiteConnectionModalOpen, setNetsuiteConnectionModalOpen] =
    useState(false);
  const [salesforceConnectionModalOpen, setSalesforceConnectionModalOpen] =
    useState(false);
  const { environmentType } = useEnvironment();
  const { data, loading } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: customerID,
      environment_type: environmentType,
    },
  });
  const netSuiteEnabled = useFeatureFlag<boolean>("netsuite", false);
  const gcpMarketplaceEnabled = useFeatureFlag<boolean>(
    "gcp-marketplace-integration",
    false,
  );

  let awsMultiSettings:
    | MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>
    | undefined;
  let azureMultiSettings:
    | MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>
    | undefined;
  let stripeMultiSettings:
    | MultiBPCustomerSettings<StripeCustomerSettings>
    | undefined;
  let salesforceConnectionSettings:
    | SalesforceConnectionCustomerSettings
    | undefined;
  let netSuiteConnectionSettings:
    | NetSuiteConnectionCustomerSettings
    | undefined;

  let customerSettingsInfo:
    | Partial<CustomerIntegrationSettingsInfo>
    | undefined;
  let connectedBPs: FirstClassIntegrations[] | undefined;
  if (!loading && data) {
    customerSettingsInfo = parseIntegrationCustomerSettings(data);
    connectedBPs = customerSettingsInfo.connectedBPs;
    awsMultiSettings = customerSettingsInfo.awsMultiSettings;
    azureMultiSettings = customerSettingsInfo.azureMultiSettings;
    stripeMultiSettings = customerSettingsInfo.stripeMultiSettings;
    salesforceConnectionSettings =
      customerSettingsInfo.salesforceConnectionSettings;
    netSuiteConnectionSettings =
      customerSettingsInfo.netSuiteConnectionSettings;
  }

  const { data: clientConfig } = useGetClientConfigQuery();
  const clientHasDeltaStreamEnabled =
    clientConfig?.is_delta_stream_enabled ?? false;

  const clientGracePeriodHours = parseClientGracePeriodHours(clientConfig);
  const GRACE_PERIOD_BUFFER_HOURS = 2;
  const grace_period_hours = clientGracePeriodHours + GRACE_PERIOD_BUFFER_HOURS;

  const activeAwsContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.AwsMarketplace,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.AwsMarketplace,
  );
  const hasActiveAwsContractsOrPlan =
    !!activeAwsContractsAndPlan.contracts?.length ||
    !!activeAwsContractsAndPlan.plan;

  const activeAzureContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Azure,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Azure,
  );
  const hasActiveAzureContractsOrPlan =
    !!activeAzureContractsAndPlan.contracts?.length ||
    !!activeAzureContractsAndPlan.plan;

  const activeGcpContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Gcp,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Gcp,
  );
  const hasActiveGcpContractsOrPlan =
    !!activeGcpContractsAndPlan.contracts?.length ||
    !!activeGcpContractsAndPlan.plan;

  const activeStripeContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Stripe,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Stripe,
  );
  const hasActiveStripeContractsOrPlan =
    !!activeStripeContractsAndPlan.contracts?.length ||
    !!activeStripeContractsAndPlan.plan;

  const {
    aws: { isConnected: awsIsConnected },
    azure: { isConnected: azureIsConnected },
    stripe: { isConnected: stripeIsConnected },
  } = getCustomerIntegrationCardVisibility(
    connectedBPs,
    {
      aws: hasActiveAwsContractsOrPlan,
      azure: hasActiveAzureContractsOrPlan,
      gcp: hasActiveGcpContractsOrPlan,
      stripe: hasActiveStripeContractsOrPlan,
    },
    {
      aws: !!customerSettingsInfo?.clientHasAws,
      azure: !!customerSettingsInfo?.clientHasAzure,
      stripe: !!customerSettingsInfo?.clientHasStripe,
    },
    {
      deltaStream: clientHasDeltaStreamEnabled,
      gcp: !!gcpMarketplaceEnabled,
    },
  );

  const mapConnectedContractsAndPlan = (contractsAndPlan?: {
    contracts?: ParsedContract[];
    plan?: ParsedCustomerPlan;
  }): ConnectedContractOrPlan[] | undefined => {
    return contractsAndPlan?.contracts
      ?.map(
        (c, index): ConnectedContractOrPlan => ({
          id: c.id,
          name: c.name || `Contract ${index + 1}`,
          contractOrPlan: "contract" as const,
        }),
      )
      .concat(
        contractsAndPlan?.plan
          ? [
              {
                id: contractsAndPlan?.plan.id,
                name: contractsAndPlan?.plan.name,
                contractOrPlan: "plan" as const,
              } as ConnectedContractOrPlan,
            ]
          : [],
      );
  };

  function filterConnectedContractsAndPlan<
    T extends
      | SalesforceConnectionCustomerSettings
      | NetSuiteConnectionCustomerSettings
      | AWSMarketplaceCustomerSettings
      | AzureMarketplaceCustomerSettings
      | StripeCustomerSettings,
  >(
    selectedConfig: MultiBPCustomerSettings<T>[number],
    contractsAndPlan: {
      contracts?: ParsedContract[];
      plan?: ParsedCustomerPlan;
    },
  ): ParsedContractsAndPlan {
    const customerBillingProviderConfigurationID =
      "customerBillingProviderConfigurationID" in selectedConfig
        ? selectedConfig?.customerBillingProviderConfigurationID
        : undefined;
    return {
      contracts: contractsAndPlan.contracts?.filter(
        (c) =>
          c.customer_billing_provider_configuration?.id ===
          customerBillingProviderConfigurationID,
      ),
      plan: !customerBillingProviderConfigurationID
        ? contractsAndPlan.plan
        : undefined,
    };
  }

  const [selectedAwsSetting, setSelectedAwsSetting] = useState<
    AWSMarketplaceCustomerSettings | undefined
  >(undefined);
  const [selectedAzureSetting, setSelectedAzureSetting] = useState<
    AzureMarketplaceCustomerSettings | undefined
  >(undefined);
  const [selectedStripeSetting, setSelectedStripeSetting] = useState<
    StripeCustomerSettings | undefined
  >(undefined);

  return (
    <div className={classNames}>
      <ConnectedCustomerIntegrationsTable
        customerID={customerID}
        rows={[
          ...(awsMultiSettings ?? []).map((awsSettings) => ({
            name: "AWS Marketplace",
            settings: awsSettings,
            displayedSettings:
              awsSettings.customerId &&
              awsSettings.productCode &&
              awsSettings.region
                ? {
                    "Customer ID": awsSettings.customerId,
                    "Product code": awsSettings.productCode,
                    Region: awsSettings.region,
                    "Is subscription product?": {
                      value: awsSettings?.isSubscriptionProduct ? "Yes" : "No",
                      hasBadgeLabel: true as const,
                    },
                  }
                : undefined,
            contractsAndPlan:
              filterConnectedContractsAndPlan<AWSMarketplaceCustomerSettings>(
                awsSettings,
                activeAwsContractsAndPlan,
              ),
            onEdit: () => {
              setSelectedAwsSetting(awsSettings);
              setAWSMarketplaceSettingsModalOpen(true);
            },
          })),
          ...(azureMultiSettings ?? []).map((azureSettings) => ({
            name: "Azure Marketplace",
            settings: azureSettings,
            displayedSettings: azureSettings.subscriptionId
              ? {
                  "Subscription ID": azureSettings.subscriptionId,
                }
              : undefined,
            contractsAndPlan:
              filterConnectedContractsAndPlan<AzureMarketplaceCustomerSettings>(
                azureSettings,
                activeAzureContractsAndPlan,
              ),
            onEdit: () => {
              setSelectedAzureSetting(azureSettings);
              setAzureMarketplaceSettingsModalOpen(true);
            },
          })),
          ...(stripeMultiSettings ?? []).map((stripeSettings) => ({
            name: "Stripe",
            settings: stripeSettings,
            displayedSettings:
              stripeSettings.customerId && stripeSettings.collectionMethod
                ? {
                    "Customer ID": stripeSettings.customerId,
                    "Collection Method": {
                      value:
                        stripeSettings.collectionMethod ===
                        "charge_automatically"
                          ? "Automatic"
                          : stripeSettings.collectionMethod === "send_invoice"
                            ? "Send Invoice"
                            : stripeSettings.collectionMethod,
                      hasBadgeLabel: true as const,
                    },
                  }
                : undefined,
            contractsAndPlan:
              filterConnectedContractsAndPlan<StripeCustomerSettings>(
                stripeSettings,
                activeStripeContractsAndPlan,
              ),
            onEdit: () => {
              setSelectedStripeSetting(stripeSettings);
              setStripeSettingsModalOpen(true);
            },
          })),
          {
            name: "Netsuite",
            settings: netSuiteConnectionSettings,
            displayedSettings: netSuiteConnectionSettings?.netSuiteCustomerId
              ? {
                  "Customer ID": netSuiteConnectionSettings?.netSuiteCustomerId,
                }
              : undefined,
            onEdit: () => setNetsuiteConnectionModalOpen(true),
          },
          {
            name: "Salesforce",
            settings: salesforceConnectionSettings,
            displayedSettings: salesforceConnectionSettings?.salesforceAccountId
              ? {
                  "Account ID":
                    salesforceConnectionSettings?.salesforceAccountId,
                }
              : undefined,
            onEdit: () => setSalesforceConnectionModalOpen(true),
          },
        ]
          .map(
            ({
              name,
              settings,
              onEdit,
              ...props
            }): ConnectedCustomerIntegrationRow | undefined =>
              settings?.connectionDate && {
                integrationName:
                  name as ConnectedCustomerIntegrationRow["integrationName"],
                integrationDisplayDetails: (
                  <IntegrationDetails
                    integrationName={name}
                    displayedSettings={
                      "displayedSettings" in props
                        ? (props?.displayedSettings as IntegrationDetailProps["displayedSettings"])
                        : undefined
                    }
                    supportingText={renderDate(settings.connectionDate, {
                      isUtc: false,
                    })}
                  />
                ),
                connectedContractsAndPlan: mapConnectedContractsAndPlan(
                  "contractsAndPlan" in props
                    ? props?.contractsAndPlan
                    : undefined,
                ),
                dateConnected: settings.connectionDate,
                onEdit,
              },
          )
          .filter((row): row is ConnectedCustomerIntegrationRow => !!row)}
      />

      <div className="mt-[24px] grid gap-[12px]">
        <SectionHeader
          title="Available Integrations"
          subtitle="Connect to new upstream and downstream datasets."
        ></SectionHeader>

        {/* Integration Cards */}
        {!awsIsConnected && (
          <AwsIntegrationCard
            className="w-full"
            cardProps={{ wrapContents: false }}
            triggerSetupAction={() => setAWSMarketplaceSettingsModalOpen(true)}
          />
        )}

        {!azureIsConnected && (
          <AzureIntegrationCard
            className="w-full"
            cardProps={{ wrapContents: false }}
            triggerSetupAction={() =>
              setAzureMarketplaceSettingsModalOpen(true)
            }
          />
        )}

        {!stripeIsConnected && (
          <StripeIntegrationCard
            className="w-full"
            cardProps={{ wrapContents: false }}
            triggerSetupAction={() => setStripeSettingsModalOpen(true)}
          />
        )}

        {!netSuiteEnabled && netSuiteConnectionSettings?.connectionDate && (
          <BaseIntegrationCard
            logo={NetSuiteLogo}
            title="Netsuite"
            subheader="Accounting/Revenue integration"
            description="Send accounting/revenue information to NetSuite"
            className="w-full"
            cardProps={{ wrapContents: false }}
            buttonOptions={{
              buttonOnClick: () => setNetsuiteConnectionModalOpen(true),
            }}
          />
        )}

        {!salesforceConnectionSettings?.connectionDate && (
          <SalesforceIntegrationCard
            className="w-full"
            cardProps={{ wrapContents: false }}
            triggerSetupAction={() => setSalesforceConnectionModalOpen(true)}
          />
        )}

        {/* Setup Integration Modals */}
        {awsMarketplaceSettingsModalOpen && (
          <AWSMarketplaceSettingsModal
            onClose={() => setAWSMarketplaceSettingsModalOpen(false)}
            customerId={customerID}
            edit={awsIsConnected}
            awsCustomerID={selectedAwsSetting?.customerId}
            awsProductCode={selectedAwsSetting?.productCode}
            awsRegion={selectedAwsSetting?.region}
          />
        )}

        {azureMarketplaceSettingsModalOpen && (
          <AzureMarketplaceSettingsModal
            onClose={() => setAzureMarketplaceSettingsModalOpen(false)}
            customerID={customerID}
            edit={azureIsConnected}
            azureSubscriptionID={selectedAzureSetting?.subscriptionId}
          />
        )}

        {stripeSettingsModalOpen && (
          <StripeSettingsModal
            onClose={() => setStripeSettingsModalOpen(false)}
            edit={stripeIsConnected}
            customerId={customerID}
            stripeCustomerID={selectedStripeSetting?.customerId}
            stripeCollectionMethod={selectedStripeSetting?.collectionMethod}
          />
        )}

        {netsuiteConnectionModalOpen && (
          <NetSuiteConnectionModal
            isOpen={netsuiteConnectionModalOpen}
            onClose={() => setNetsuiteConnectionModalOpen(false)}
            netSuiteCustomerId={netSuiteConnectionSettings?.netSuiteCustomerId}
            customerId={customerID}
          />
        )}

        {salesforceConnectionModalOpen && (
          <SalesforceConnectionModal
            isOpen={salesforceConnectionModalOpen}
            onClose={() => setSalesforceConnectionModalOpen(false)}
            salesforceAccountId={
              salesforceConnectionSettings?.salesforceAccountId
            }
            customerId={customerID}
          />
        )}
      </div>
    </div>
  );
};

type ConnectedContractOrPlan = {
  id: string;
  name: string;
  contractOrPlan: "plan" | "contract";
};

export interface ConnectedCustomerIntegrationRow {
  integrationName:
    | "Stripe"
    | "AWS Marketplace"
    | "Azure Marketplace"
    | "Netsuite"
    | "Salesforce";
  integrationDisplayDetails: React.JSX.Element;
  connectedContractsAndPlan: ConnectedContractOrPlan[] | undefined;
  dateConnected: Date;
  onEdit: () => void;
}

interface ConnectedCustomerIntegrationsTableProps {
  rows: ConnectedCustomerIntegrationRow[];
  customerID: string;
}

export const ConnectedCustomerIntegrationsTable = ({
  customerID,
  rows,
}: ConnectedCustomerIntegrationsTableProps) => {
  const tableColumns: Column<
    ConnectedCustomerIntegrationRow & { id: string }
  >[] = [
    {
      id: "1",
      accessorKey: "integrationDisplayDetails",
      header: "Integration",
      cell: (props: { getValue: () => React.ReactElement }) => {
        return props.getValue();
      },
      tooltipContent: "Integrations connected to this customer",
      enableSorting: false,
    },
    {
      id: "2",
      accessorKey: "connectedContractsAndPlan",
      header: "Connected Contracts or Plan",
      cell: (props: {
        getValue: () => ConnectedContractOrPlan[] | undefined;
      }) => {
        const contractsAndPlan = props.getValue();
        if (!contractsAndPlan) return null;
        return (
          <div className="flex">
            {contractsAndPlan?.length === 1 ? (
              <>{contractsAndPlan[0].name}</>
            ) : (
              <>{contractsAndPlan?.length} contracts</>
            )}
          </div>
        );
      },
      tooltipContent:
        "Contracts and plans billing through/connected to this integration",
      enableSorting: false,
    },
    {
      id: "4",
      header: "",
      accessorFn: (row) => row,
      cell: (props: { getValue: () => ConnectedCustomerIntegrationRow }) => (
        <ConnectedCustomerIntegrationRowActionButtons
          {...props.getValue()}
          customerID={customerID}
        />
      ),
    },
  ];

  const rowsWithId = rows.map((row, index) => ({
    ...row,
    id: index.toString(),
  }));
  return (
    <Table
      title="Connected integrations"
      columns={tableColumns}
      data={rowsWithId}
    />
  );
};

const ConnectedCustomerIntegrationRowActionButtons: React.FC<
  ConnectedCustomerIntegrationRow & { customerID: string }
> = ({ integrationName, connectedContractsAndPlan, onEdit, customerID }) => {
  const navigate = useNavigate();
  const { newUIEnabled } = useUIMode();
  return (
    <Dropdown icon="dotsVertical">
      <DropdownItem
        label="View integration"
        value="View integration"
        linkTo={
          newUIEnabled
            ? "/connections/integrations"
            : "/settings/general/integrations"
        }
      />
      <DropdownItem
        label={`Edit ${integrationName}`}
        value={`Edit ${integrationName}`}
        onClick={onEdit}
      />
      {!!connectedContractsAndPlan?.length && (
        <DropdownHeader text="Connected contracts" />
      )}
      {connectedContractsAndPlan?.map((contractOrPlan) => (
        <DropdownItem
          key={"contract-or-plan-" + contractOrPlan.id}
          label={contractOrPlan.name}
          value={contractOrPlan.name}
          icon="arrowUpRight"
          onClick={() =>
            navigate(
              `/customers/${customerID}/${contractOrPlan.contractOrPlan}s/${contractOrPlan.id}`,
            )
          }
        />
      ))}
    </Dropdown>
  );
};
