/* 3c278505fb21c2dac6c84ee58c514375a78034df
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupAzureMarketplaceConfigMutationVariables = Types.Exact<{
  tenant_id: Types.Scalars['String'];
  client_id: Types.Scalars['String'];
  raw_client_secret: Types.Scalars['String'];
}>;


export type SetupAzureMarketplaceConfigMutation = { __typename?: 'Mutation', store_azure_marketplace_credentials: { __typename?: 'MutationResponse', success: boolean } };


export const SetupAzureMarketplaceConfigDocument = gql`
    mutation SetupAzureMarketplaceConfig($tenant_id: String!, $client_id: String!, $raw_client_secret: String!) {
  store_azure_marketplace_credentials(
    azure_tenant_id: $tenant_id
    azure_client_id: $client_id
    raw_azure_client_secret: $raw_client_secret
  ) {
    success
  }
}
    `;
export type SetupAzureMarketplaceConfigMutationFn = Apollo.MutationFunction<SetupAzureMarketplaceConfigMutation, SetupAzureMarketplaceConfigMutationVariables>;

/**
 * __useSetupAzureMarketplaceConfigMutation__
 *
 * To run a mutation, you first call `useSetupAzureMarketplaceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAzureMarketplaceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAzureMarketplaceConfigMutation, { data, loading, error }] = useSetupAzureMarketplaceConfigMutation({
 *   variables: {
 *      tenant_id: // value for 'tenant_id'
 *      client_id: // value for 'client_id'
 *      raw_client_secret: // value for 'raw_client_secret'
 *   },
 * });
 */
export function useSetupAzureMarketplaceConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetupAzureMarketplaceConfigMutation, SetupAzureMarketplaceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupAzureMarketplaceConfigMutation, SetupAzureMarketplaceConfigMutationVariables>(SetupAzureMarketplaceConfigDocument, options);
      }
export type SetupAzureMarketplaceConfigMutationHookResult = ReturnType<typeof useSetupAzureMarketplaceConfigMutation>;
export type SetupAzureMarketplaceConfigMutationResult = Apollo.MutationResult<SetupAzureMarketplaceConfigMutation>;
export type SetupAzureMarketplaceConfigMutationOptions = Apollo.BaseMutationOptions<SetupAzureMarketplaceConfigMutation, SetupAzureMarketplaceConfigMutationVariables>;