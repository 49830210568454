import React from "react";
import { NumericInput, Toggle } from "design-system";
import { Schema } from "../Schema";
import { RateCtrl, useRateController } from "./RateCtrl";

const usePercentagePriceController = useRateController.child(
  Schema.PercentagePrice,
  {
    read(parent) {
      const price = parent.get("price");
      return price?.type === "percentage" ? price : undefined;
    },
    write: (child) => ({
      price: {
        type: "percentage",
        fraction: child.get("fraction"),
        useListPrices: child.get("useListPrices"),
      },
    }),
  },
);

interface Props {
  parent: RateCtrl;
}

export const RatePercent: React.FC<Props> = (props) => {
  const percentageCtrl = usePercentagePriceController(props.parent);

  return (
    <div>
      <NumericInput
        {...percentageCtrl.props.NumericInput("fraction", {
          name: "Rate",
          tooltip:
            "Composite products are priced as a percentage of some other set of products.",
          placeholder: "0.0",
          suffix: "%",
        })}
        className="w-[6rem]"
      />
      <Toggle
        {...percentageCtrl.props.Toggle("useListPrices", {
          label: "Use list prices",
          theme: "primary",
          className: "items-center",
        })}
      />
    </div>
  );
};
