import { createContext, useContext } from "react";

export type ProductAndRateInfo = {
  product_id: string;
  group_keys?: string[];
  // group_keys: ["region", "hardware"];
  dimensions?: { key: string; values: string[] }[];
  // dimensions: [
  //   {
  //     key: "region";
  //     values: ["west", "east"];
  //   },
  //   {
  //     key: "hardware";
  //     values: ["gpu2x"]
  //   },
  // ];
  pricing?: {
    price: number;
    pricing_group_values?: { name: string; value: string }[];
  }[];
  // pricing: [
  //   {
  //     price: 1;
  //     pricing_group_values: [
  //       {
  //         name: "region";
  //         value: "west";
  //       },
  //       {
  //         name: "hardware";
  //         value: "gpu2x";
  //       },
  //     ];
  //   },
  //   {
  //     price: 2;
  //     pricing_group_values: [
  //       {
  //         name: "region";
  //         value: "east";
  //       },
  //       {
  //         name: "hardware";
  //         value: "gpu2x";
  //       },
  //     ];
  //   },
  // ];
};

interface ProductAndRateContextType {
  products: ProductAndRateInfo[];
  setProducts: (products: ProductAndRateInfo[]) => void;
}

export const ProductAndRateContext =
  createContext<ProductAndRateContextType | null>(null);

export const useProductAndRateContext = (): ProductAndRateContextType => {
  const ctx = useContext(ProductAndRateContext);
  if (ctx === null) {
    throw new Error(
      "Cannot useProductAndRateContext in a context that doesn't have a rate card",
    );
  }
  return ctx;
};
