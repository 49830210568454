import React from "react";
import { Input } from "design-system";

import { useSearcher } from "lib/search/useSearcher";
import {
  RateRow,
  LegacyRatesTable,
} from "pages/Contracts/components/LegacyRatesTable";
import { SearchableRateRow } from "pages/Contracts/Pricing/CreateAndEditRateCard/Rates";

interface Props {
  name: string;
  rateRows: SearchableRateRow[];
  onSelectedProduct?: (id: string) => void;
  onSelectRow?: (row: RateRow) => void;
  loading?: boolean;
}

/** @deprecated No support for giga-rate-cards, will be removing shortly */
export const LegacyRateCardPanel: React.FC<Props> = ({
  name,
  rateRows,
  onSelectedProduct,
  onSelectRow,
  loading,
}) => {
  const [rateFilter, setRateFilter] = React.useState<string>("");

  const searchRates = useSearcher(rateRows, {
    keys: ["_productNames"],
    uuidKeys: ["_productId"],
  });
  const rateRowsFiltered = searchRates(rateFilter);

  return (
    <LegacyRatesTable
      title={name}
      onSelectProductId={onSelectedProduct}
      onSelectRow={onSelectRow}
      rateRows={rateRowsFiltered}
      className="mt-12"
      loading={loading}
      controls={
        <Input
          type="search"
          placeholder="Search rates"
          value={rateFilter}
          onChange={(v) => setRateFilter(v)}
        />
      }
    />
  );
};
