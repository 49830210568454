/* 0af657cecf9c257072035e4b4bc9cddcf2e9163c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelPlanMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  customer_plan_id: Types.Scalars['uuid'];
  cancellation_date: Types.Scalars['timestamptz'];
}>;


export type CancelPlanMutation = { __typename?: 'Mutation', end_customer_plan: { __typename?: 'MutationResponse', success: boolean, error: string | null } };

export type SuperEndCustomerPlanMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  customer_plan_id: Types.Scalars['uuid'];
  cancellation_date: Types.Scalars['timestamptz'];
  void_stripe_invoices: Types.Scalars['Boolean'];
}>;


export type SuperEndCustomerPlanMutation = { __typename?: 'Mutation', mri_end_customer_plan: { __typename?: 'CustomerPlan', id: string } | null };

export type LatestInvoiceFinalizationDateQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type LatestInvoiceFinalizationDateQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, end_date_of_last_finalized_invoice: string | null } | null };


export const CancelPlanDocument = gql`
    mutation CancelPlan($customer_id: uuid!, $customer_plan_id: uuid!, $cancellation_date: timestamptz!) {
  end_customer_plan(
    customer_id: $customer_id
    customer_plan_id: $customer_plan_id
    cancellation_date: $cancellation_date
  ) {
    success
    error
  }
}
    `;
export type CancelPlanMutationFn = Apollo.MutationFunction<CancelPlanMutation, CancelPlanMutationVariables>;

/**
 * __useCancelPlanMutation__
 *
 * To run a mutation, you first call `useCancelPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlanMutation, { data, loading, error }] = useCancelPlanMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      customer_plan_id: // value for 'customer_plan_id'
 *      cancellation_date: // value for 'cancellation_date'
 *   },
 * });
 */
export function useCancelPlanMutation(baseOptions?: Apollo.MutationHookOptions<CancelPlanMutation, CancelPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPlanMutation, CancelPlanMutationVariables>(CancelPlanDocument, options);
      }
export type CancelPlanMutationHookResult = ReturnType<typeof useCancelPlanMutation>;
export type CancelPlanMutationResult = Apollo.MutationResult<CancelPlanMutation>;
export type CancelPlanMutationOptions = Apollo.BaseMutationOptions<CancelPlanMutation, CancelPlanMutationVariables>;
export const SuperEndCustomerPlanDocument = gql`
    mutation SuperEndCustomerPlan($customer_id: uuid!, $customer_plan_id: uuid!, $cancellation_date: timestamptz!, $void_stripe_invoices: Boolean!) {
  mri_end_customer_plan(
    customer_id: $customer_id
    customer_plan_id: $customer_plan_id
    cancellation_date: $cancellation_date
    void_stripe_invoices: $void_stripe_invoices
  ) {
    id
  }
}
    `;
export type SuperEndCustomerPlanMutationFn = Apollo.MutationFunction<SuperEndCustomerPlanMutation, SuperEndCustomerPlanMutationVariables>;

/**
 * __useSuperEndCustomerPlanMutation__
 *
 * To run a mutation, you first call `useSuperEndCustomerPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuperEndCustomerPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [superEndCustomerPlanMutation, { data, loading, error }] = useSuperEndCustomerPlanMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      customer_plan_id: // value for 'customer_plan_id'
 *      cancellation_date: // value for 'cancellation_date'
 *      void_stripe_invoices: // value for 'void_stripe_invoices'
 *   },
 * });
 */
export function useSuperEndCustomerPlanMutation(baseOptions?: Apollo.MutationHookOptions<SuperEndCustomerPlanMutation, SuperEndCustomerPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuperEndCustomerPlanMutation, SuperEndCustomerPlanMutationVariables>(SuperEndCustomerPlanDocument, options);
      }
export type SuperEndCustomerPlanMutationHookResult = ReturnType<typeof useSuperEndCustomerPlanMutation>;
export type SuperEndCustomerPlanMutationResult = Apollo.MutationResult<SuperEndCustomerPlanMutation>;
export type SuperEndCustomerPlanMutationOptions = Apollo.BaseMutationOptions<SuperEndCustomerPlanMutation, SuperEndCustomerPlanMutationVariables>;
export const LatestInvoiceFinalizationDateDocument = gql`
    query LatestInvoiceFinalizationDate($customer_id: uuid!) {
  customer: Customer_by_pk(id: $customer_id) {
    id
    end_date_of_last_finalized_invoice
    __environment_type: environment_type
  }
}
    `;

/**
 * __useLatestInvoiceFinalizationDateQuery__
 *
 * To run a query within a React component, call `useLatestInvoiceFinalizationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestInvoiceFinalizationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestInvoiceFinalizationDateQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useLatestInvoiceFinalizationDateQuery(baseOptions: Apollo.QueryHookOptions<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>(LatestInvoiceFinalizationDateDocument, options);
      }
export function useLatestInvoiceFinalizationDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>(LatestInvoiceFinalizationDateDocument, options);
        }
export type LatestInvoiceFinalizationDateQueryHookResult = ReturnType<typeof useLatestInvoiceFinalizationDateQuery>;
export type LatestInvoiceFinalizationDateLazyQueryHookResult = ReturnType<typeof useLatestInvoiceFinalizationDateLazyQuery>;
export type LatestInvoiceFinalizationDateQueryResult = Apollo.QueryResult<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>;