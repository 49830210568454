import React from "react";
import { useProductAndRateContext } from "./ProductAndRateContext";
import { Select } from "../../../../design-system";

export const Step2: React.FC = () => {
  const { products, setProducts } = useProductAndRateContext();

  return (
    <div>
      <div className="mb-[10px]">Step 2 content</div>
      <div className="mb-[10px]">
        Current products:{" "}
        {products.map((product) => product.product_id).join(", ")}
      </div>
      <Select
        name="add product id"
        placeholder=""
        options={[
          {
            value: "product-3",
            label: "product-3",
          },
          {
            value: "product-4",
            label: "product-4",
          },
        ]}
        onChange={(v) => {
          setProducts([
            ...products,
            {
              product_id: v,
            },
          ]);
        }}
        value={
          products.length ? products[products.length - 1].product_id : undefined
        }
      />
    </div>
  );
};
