import React from "react";
import classNames from "classnames";
import Decimal from "decimal.js";
import { Badge } from "design-system";

import { TablePanel } from "components/TablePanel";
import {
  USD_CREDIT_TYPE as USD,
  USD_CREDIT_TYPE,
  displayCreditsInCurrencyWithoutRounding,
} from "lib/credits";

import { ColWidths } from "../lib/ColWidths";
import { printDateTime, Dayjs, useNow } from "lib/date";
import { CellWithSubtitle } from "./CellWithSubtitle";
import {
  RowTheme,
  Table,
  TableProps,
  BasicPaginationOverListDefaults,
  useBasicPaginationOverList,
} from "components/Table";
import { ProductListItem } from "../lib/ProductListItem";
import { Custom, Tiered } from "./RateScheduleTable";
import { CreditType } from "types/credit-types";
export type Tier = {
  size?: Decimal;
  unitPrice: Decimal;
  lastUnit?: Decimal;
};
type BaseRate = {
  type: "fixed" | "subscription" | "multiple" | "tiered";
  unitPrice: Decimal;
  quantity?: Decimal;
  isProrated?: boolean;
  tiers?: Tier[];
};

export type Price =
  | {
      type: "multiple";
      multiplier: Decimal;
      baseRate?: BaseRate;
      overrideType?: "overwrite" | "multiplier";
    }
  | {
      type: "fixed";
      unitPrice: Decimal;
      baseRate?: BaseRate;
    }
  | {
      type: "subscription";
      unitPrice: Decimal;
      quantity: Decimal;
      isProrated: boolean;
      baseRate?: BaseRate;
    }
  | {
      type: "tiered";
      baseRate?: BaseRate;
      tiers: Tier[];
    }
  | {
      type: "custom";
    };

export interface RateRow {
  type: "base" | "rateChange" | "override" | "overrideEnded";
  product: ProductListItem.TypeFragment &
    ProductListItem.IdFragment &
    ProductListItem.NameFragment &
    ProductListItem.TagsFragment;
  isCurrent: boolean;
  from?: Dayjs;
  to?: Dayjs;
  entitled?: boolean;
  useListPrices: boolean | undefined;
  price?: Price;
  id?: string;
  rowTheme?: RowTheme;
  creditType?: CreditType;
}

interface Props {
  title?: string;
  rateRows: Array<RateRow>;
  onSelectProductId?: (productId: string) => void;
  onSelectRow?: (row: RateRow) => void;
  className?: string;
  controls?: React.ReactNode;
  loading?: boolean;
}

const rateRowTypeToPrintable = (t: RateRow["type"]) => {
  switch (t) {
    case "base":
      return "Base";
    case "rateChange":
      return "Rate card change";
    case "override":
      return "Override";
    case "overrideEnded":
      return "Override ended";
    default:
      t satisfies never;
  }
};

const topAlignBaseRows = (base?: string) => (row?: RateRow) =>
  classNames(base, row?.type === "base" ? "align-top" : undefined);

const DEFAULT_PAGINATION: BasicPaginationOverListDefaults = {
  count: 1,
  current: 1,
  size: 50,
};

export const LegacyRatesTable: React.FC<Props> = (props) => {
  const now = useNow();
  const basicPagination = useBasicPaginationOverList(
    props.rateRows,
    DEFAULT_PAGINATION,
  );

  const tableProps: TableProps<RateRow> = {
    onRowClick:
      !props.onSelectProductId && !props.onSelectRow
        ? undefined
        : (row) => {
            if (props.onSelectRow) {
              props.onSelectRow(row);
            }
            if (props.onSelectProductId) {
              props.onSelectProductId(row.product.id);
            }
          },
    noPageReset: true,
    getRowTheme: (row) => (row.rowTheme ? row.rowTheme : "enabled"),
    data: basicPagination.rows,
    loading: props.loading,
    skeletonRows: 3,
    basicPagination: basicPagination.props,
    columns: [
      {
        id: "product",
        header: "Product",
        cellClassName: topAlignBaseRows("!py-[8px]"),
        render(row) {
          return row.type === "base" || row.type === "rateChange" ? (
            <CellWithSubtitle
              title={
                <div className="flex flex-wrap items-center gap-8">
                  {ProductListItem.getName(row.product, now)}
                  {row.type === "rateChange" && row.isCurrent ? (
                    <Badge theme="primary" type="light">
                      CURRENT
                    </Badge>
                  ) : (
                    ""
                  )}
                </div>
              }
              subtitle={ProductListItem.printType(row.product)}
            />
          ) : (
            <>
              <div className="my-[-10px] mr-8 inline-block h-[2.3em] rounded-small bg-primary-200 p-[2px]" />
              {rateRowTypeToPrintable(row.type)}
              {row.isCurrent ? (
                <Badge theme="primary" type="light" className="ml-8">
                  CURRENT
                </Badge>
              ) : null}
            </>
          );
        },
      },
      {
        id: "entitled",
        header: "Entitlement",
        align: "right",
        cellClassName: topAlignBaseRows("w-[100px]"),
        render(row) {
          return row.entitled == null
            ? "--"
            : row.entitled
              ? "Enabled"
              : "Disabled";
        },
      },
      {
        id: "effectiveStart",
        header: "Effective start",
        align: "right",
        cellClassName: topAlignBaseRows(ColWidths.DATE),
        render(row) {
          if (row.from == null) {
            return "--";
          }

          return printDateTime(row.from);
        },
      },
      {
        id: "effectiveEnd",
        header: "Effective end",
        align: "right",
        cellClassName: topAlignBaseRows(ColWidths.DATE),
        render(row) {
          if (row.to == null) {
            return "--";
          }

          return printDateTime(row.to);
        },
      },
      {
        id: "rate",
        header: "Rate",
        align: "right",
        cellClassName: topAlignBaseRows(ColWidths.RATE),
        render(row) {
          if (row.price == null) {
            return "--";
          }

          if (row.price.type === "multiple") {
            const suffix = row.useListPrices ? " (uses list prices)" : "";

            // Percentage rate with no override or OVERWRITE override
            if (
              !row.price.baseRate ||
              !row.price.overrideType ||
              row.price.overrideType === "overwrite"
            ) {
              return `${row.price.multiplier.mul(100)}%` + suffix;
            }

            // Percentage rate with a MULTIPLIER override
            if (
              row.product.__typename == "CompositeProductListItem" &&
              row.price.baseRate
            ) {
              return (
                `${row.price.multiplier.mul(row.price.baseRate.unitPrice).mul(100)}%` +
                suffix
              );
            }
            if (row.price.baseRate.type === "subscription") {
              return (
                <>
                  {displayCreditsInCurrencyWithoutRounding(
                    row.price.multiplier.mul(row.price.baseRate.unitPrice),
                    row.creditType ?? USD,
                  )}
                  {row.product.__typename === "SubscriptionProductListItem" &&
                    ` (x${row.price.baseRate.quantity})${
                      row.price.baseRate.isProrated ? " (prorated)" : ""
                    }`}
                </>
              );
            }
            // Flat rate with a MULTIPLIER override
            return (
              <>
                {displayCreditsInCurrencyWithoutRounding(
                  row.price.multiplier.mul(row.price.baseRate.unitPrice),
                  row.creditType ?? USD,
                )}
              </>
            );
          } else if (row.price.type === "subscription") {
            // Subscription rate with no override or OVERWRITE override
            return (
              <>
                {displayCreditsInCurrencyWithoutRounding(
                  row.price.unitPrice,
                  row.creditType ?? USD,
                )}
                {row.product.__typename === "SubscriptionProductListItem" &&
                  ` (x${row.price.quantity})${
                    row.price.isProrated ? " (prorated)" : ""
                  }`}
              </>
            );
          } else if (row.price.type === "fixed") {
            // Flat rate with NO override or OVERWRITE override
            return (
              <>
                {displayCreditsInCurrencyWithoutRounding(
                  row.price.unitPrice,
                  row.creditType ?? USD,
                )}
              </>
            );
          } else if (row.price.type === "tiered") {
            return (
              <Tiered
                tiers={row.price.tiers}
                creditType={row.creditType ?? USD_CREDIT_TYPE}
              />
            );
          } else if (row.price.type === "custom") {
            return <Custom />;
          }
          return "--";
        },
      },
    ],
  };

  return props.title !== undefined ? (
    <TablePanel<RateRow>
      title={props.title}
      className={props.className}
      controls={props.controls}
      {...tableProps}
    />
  ) : (
    <Table<RateRow> {...tableProps} />
  );
};
