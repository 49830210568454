/* 19ba1099f35482e153cab24dd4d1556f1104bdb0
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VoidCreditGrantMutationVariables = Types.Exact<{
  credit_grant_id: Types.Scalars['uuid'];
}>;


export type VoidCreditGrantMutation = { __typename?: 'Mutation', void_credit_grant: { __typename?: 'CreditGrant', id: string, voided_at: string | null, Voider: { __typename?: 'Actor', id: string, name: string } | null } };


export const VoidCreditGrantDocument = gql`
    mutation VoidCreditGrant($credit_grant_id: uuid!) {
  void_credit_grant(credit_grant_id: $credit_grant_id) {
    id
    voided_at
    Voider {
      id
      name
    }
    __environment_type: environment_type
  }
}
    `;
export type VoidCreditGrantMutationFn = Apollo.MutationFunction<VoidCreditGrantMutation, VoidCreditGrantMutationVariables>;

/**
 * __useVoidCreditGrantMutation__
 *
 * To run a mutation, you first call `useVoidCreditGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidCreditGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidCreditGrantMutation, { data, loading, error }] = useVoidCreditGrantMutation({
 *   variables: {
 *      credit_grant_id: // value for 'credit_grant_id'
 *   },
 * });
 */
export function useVoidCreditGrantMutation(baseOptions?: Apollo.MutationHookOptions<VoidCreditGrantMutation, VoidCreditGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidCreditGrantMutation, VoidCreditGrantMutationVariables>(VoidCreditGrantDocument, options);
      }
export type VoidCreditGrantMutationHookResult = ReturnType<typeof useVoidCreditGrantMutation>;
export type VoidCreditGrantMutationResult = Apollo.MutationResult<VoidCreditGrantMutation>;
export type VoidCreditGrantMutationOptions = Apollo.BaseMutationOptions<VoidCreditGrantMutation, VoidCreditGrantMutationVariables>;