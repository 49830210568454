import { RightPane } from "components/Popup";
import { FormController } from "lib/FormController";
import React from "react";

import { Body, DateInput, Headline, Select, Subtitle } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

import { FooterBar } from "pages/Contracts/Customer/Contracts/Create/components/FooterBar";
import { useConfirmClose } from "pages/Contracts/Customer/Contracts/Create/components/ConfirmClose";
import { Schema } from "../Schema";

import { ProductListItem } from "../../lib/ProductListItem";
import { RatePercent } from "./RatePercent";
import { useRateController } from "./RateCtrl";
import { RateFlat } from "./RateFlat";
import { SearchableRateRow, toRateRows } from "./Rates";
import { useNow } from "lib/date";
import { useSnackbar } from "components/Snackbar";
import { RateRow, LegacyRatesTable } from "../../components/LegacyRatesTable";
import { RateSubscription } from "./SubscriptionRate";
import { RateTiered } from "./RateTiered";
import { CreditType } from "types/credit-types";
import { isFiat } from "pages/Contracts/lib/CreditTypes";

type Product = RateRow["product"];

const onlyRateRowToShowIsTheOneBeingEdited = (
  rateRowsToShow: SearchableRateRow[],
  ctrl: ReturnType<typeof useRateController>,
) => {
  if (rateRowsToShow.length !== 1) {
    return false;
  }
  const price = ctrl.get("price");
  switch (price?.type) {
    case "flat":
      return price.price !== undefined;
    case "tiered":
      return price.tiers.every((tier) => tier.unitPrice !== undefined);
    case "subscription":
      return price.price !== undefined && price.quantity !== undefined;
    default:
      return false;
  }
};

export const RateFlyout: React.FC<{
  products: Product[];
  rateRows: SearchableRateRow[];
  onClose: () => void;
  onSave: (rate: Schema.Types.Rate) => void;
  onDelete?: () => void;
  edit?: Schema.Types.Rate;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
}> = ({
  onClose,
  onSave,
  onDelete,
  edit,
  products,
  rateRows,
  fiatCreditTypes,
  customCreditTypes,
}) => {
  const now = useNow();
  const pushMessage = useSnackbar();

  const ctrl = useRateController(edit);
  const [confirmingClose, confirmClose] = useConfirmClose(ctrl, onClose);

  const onSubmit = FormController.useSubmitHandler(ctrl, (valid) => {
    onSave(valid);
    onClose();
  });

  const onSubmitAndAddAnother = FormController.useSubmitHandler(
    ctrl,
    (valid) => {
      onSave(valid);
      ctrl.reset();
      pushMessage({
        type: "success",
        content: "Rate saved successfully",
      });
    },
  );

  const productId = ctrl.get("productId");
  const rateRowsToShow = rateRows.filter((r) => r._productId === productId);
  const product = products.find((p) => p.id === productId);
  const valid = ctrl.getValid();
  if (valid) {
    const previewRow = toRateRows([valid], products, now)[0];
    if (edit) {
      const index = rateRowsToShow.findIndex((r) => r.id === edit.id);
      rateRowsToShow[index] = previewRow;
    } else {
      rateRowsToShow.push(previewRow);
    }
  }

  const currentlyEditingNewRate =
    rateRowsToShow.length === 1 &&
    (!!edit || onlyRateRowToShowIsTheOneBeingEdited(rateRowsToShow, ctrl));
  const initialCreditType =
    rateRowsToShow.length && !currentlyEditingNewRate
      ? rateRowsToShow[0].creditType
      : undefined;
  const validFiatCreditTypes =
    initialCreditType && isFiat(initialCreditType)
      ? [initialCreditType]
      : initialCreditType
        ? []
        : fiatCreditTypes;
  const validCustomCreditTypes =
    initialCreditType && !isFiat(initialCreditType)
      ? [initialCreditType]
      : initialCreditType
        ? []
        : customCreditTypes;

  return (
    <RightPane
      isOpen
      onRequestClose={confirmClose}
      size="xl"
      contentClassName="!p-0"
    >
      {confirmingClose}
      <form className="flex h-full flex-col" onSubmit={onSubmit}>
        <header className="flex items-center border-b border-gray-100 bg-gray-50 px-12 py-8">
          <Headline level={6} className="grow">
            {edit ? "Edit rate" : "Add a rate"}
          </Headline>
          <IconButton
            className="m-0"
            onClick={onClose}
            theme="secondary"
            icon="xClose"
          />
        </header>

        <div className="flex grow flex-col overflow-y-auto p-12">
          <Body level={1} className="mb-12 text-gray-700">
            Rates are priced products that will appear on invoices. You can add
            multiple pricing changes to a single product.
          </Body>

          <Select
            {...ctrl.props.Select("productId", {
              name: edit ? "Product" : "Search products",
              placeholder: "Search by name or ID",
              options: products.map((p) => ({
                label: ProductListItem.getName(p, now),
                value: p.id,
              })),
              className: "text-gray-600",
            })}
          />

          {product && (
            <>
              <Subtitle level={2} className="mb-12 mt-24 text-gray-900">
                Pricing
              </Subtitle>
              <div className="max-w-3xl py-4">
                <div className="grid grid-cols-3 items-start gap-x-12 gap-y-24 text-gray-600">
                  <DateInput
                    {...ctrl.props.DateInput("startingAt", {
                      name: "Starting at",
                      tooltip: "Inclusive start date for the rate",
                    })}
                  />
                  <DateInput
                    {...ctrl.props.DateInput("endingBefore", {
                      name: "Ending before (optional)",
                      minDate: ctrl.get("endingBefore"),
                      tooltip: "Exclusive end date for the rate",
                    })}
                  />

                  <Select
                    {...ctrl.props.Select("entitled", {
                      name: "Default entitlement",
                      placeholder: "Select",
                      options: [
                        { label: "Enabled", value: "enable" },
                        { label: "Disabled", value: "disable" },
                      ],
                      defaultValue: { label: "Enabled", value: "enable" },
                    })}
                  />

                  {product?.__typename === "UsageProductListItem" && (
                    <Select
                      {...ctrl.props.Select("pricingModel", {
                        name: "Pricing model",
                        placeholder: "Select",
                        options: [
                          { label: "Flat usage", value: "flat usage" },
                          { label: "Tiered usage", value: "tiered usage" },
                        ],
                        defaultValue: {
                          label: "Flat usage",
                          value: "flat usage",
                        },
                      })}
                    />
                  )}

                  {(() => {
                    switch (product?.__typename) {
                      case "CompositeProductListItem":
                        return <RatePercent parent={ctrl} />;
                      case "UsageProductListItem":
                        return ctrl.get("pricingModel") === "tiered usage" ? (
                          <div className="col-span-3">
                            <RateTiered
                              parent={ctrl}
                              fiatCreditTypes={validFiatCreditTypes}
                              customCreditTypes={validCustomCreditTypes}
                            />
                          </div>
                        ) : (
                          <RateFlat
                            parent={ctrl}
                            fiatCreditTypes={validFiatCreditTypes}
                            customCreditTypes={validCustomCreditTypes}
                          />
                        );
                      case "SubscriptionProductListItem":
                        return (
                          <RateSubscription
                            parent={ctrl}
                            fiatCreditTypes={validFiatCreditTypes}
                            customCreditTypes={validCustomCreditTypes}
                          />
                        );
                      default: {
                        throw new Error(
                          `Rates can be added for composite, usage, and subscription products only; received product type ${product?.__typename}`,
                        );
                      }
                    }
                  })()}
                </div>
              </div>

              {rateRowsToShow.length > 0 && (
                <LegacyRatesTable
                  title="Product preview"
                  rateRows={rateRowsToShow}
                  className="mt-12"
                />
              )}
            </>
          )}
        </div>
        <FooterBar
          left={
            onDelete ? (
              <Button
                onClick={onDelete}
                text="Delete"
                theme="linkDestructive"
                leadingIcon="trash03"
              />
            ) : null
          }
          right={
            <>
              <Button onClick={onClose} text="Cancel" theme="linkGray" />

              {!edit ? (
                <Button
                  disabled={!ctrl.appearsValid()}
                  onClick={onSubmitAndAddAnother}
                  text="Add another"
                  theme="secondary"
                />
              ) : (
                ""
              )}

              <Button
                disabled={!ctrl.appearsValid()}
                text={edit ? "Save" : "Add"}
                theme="primary"
                type="submit"
              />
            </>
          }
        ></FooterBar>
      </form>
    </RightPane>
  );
};
