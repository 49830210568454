import React from "react";
import { EventDetailsQuery } from "../../queries.graphql";
import { TipBox } from "components/Tips";
import { Body } from "design-system";
import styles from "./index.module.less";

type Event = EventDetailsQuery["events"][0];

const POSSIBLE_TIPS = {
  NO_CUSTOMER: {
    title: (
      <>
        Learn more: <code>customer_id</code>
      </>
    ),
    body: (
      <Body level={2}>
        The <code>customer_id</code> specifies which of your customers is
        responsible for billing associated with the event. Use the ID you
        specified when creating the customer, or an ingest alias you've added to
        the customer.
      </Body>
    ),
  },
  NO_METRIC: {
    title: "Learn more: billable metrics",
    body: (
      <Body level={2}>
        Usage events are aggregated into billable metrics. To be counted, events
        must match the definition of at least one billable metric. Make sure
        your event payload has the expected <code>event_type</code> and{" "}
        <code>properties</code>.
      </Body>
    ),
  },
  ONLY_INGEST: {
    title: "Learn more: billable metrics and customers",
    body: (
      <>
        <Body level={2}>
          Usage events are aggregated into billable metrics. To be counted,
          events must match the definition of at least one billable metric. Make
          sure your event payload has the expected <code>event_type</code> and{" "}
          <code>properties</code>.
        </Body>
        <Body level={2}>
          The <code>customer_id</code> specifies which of your customers is
          responsible for billing associated with the event. Use the ID you
          specified when creating the customer.
        </Body>
      </>
    ),
  },
  DUPLICATE: {
    title: "Learn more: duplicate events",
    body: (
      <Body level={2}>
        This event was sent with the same <code>transaction_id</code> as a prior
        event. Metronome deduplicates by the transaction ID. We suggest using a
        value that unique per event (such as a UUID). You can view other events
        with the same trasnaction ID by searching for it in the search box and
        then selecting from options on the right.
      </Body>
    ),
  },
};

type EventTimelineTipProps = {
  event: Event;
};

export const EventTimelineTip: React.FC<EventTimelineTipProps> = ({
  event,
}) => {
  let tipContents = null;

  if (event.is_duplicate) {
    tipContents = POSSIBLE_TIPS.DUPLICATE;
  } else if (!event?.billable_metrics?.length && !event?.seat_metrics.length) {
    if (!event?.customer) {
      tipContents = POSSIBLE_TIPS.ONLY_INGEST;
    } else {
      tipContents = POSSIBLE_TIPS.NO_METRIC;
    }
  } else if (!event?.customer) {
    tipContents = POSSIBLE_TIPS.NO_CUSTOMER;
  }

  return tipContents ? (
    <div className={styles.container}>
      <TipBox isFloating={false} top={0} {...tipContents} />
    </div>
  ) : null;
};
