/* f74e52b8fa4efde053cd09eebe8a8718db9472ba
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLineItemOptionsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetLineItemOptionsQuery = { __typename?: 'Query', exportSubLineItemConfig: Array<{ __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string }>, includeZeroQuantitySubLineItems: Array<{ __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string }>, stripeQuantityAlwaysString: Array<{ __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string }> };

export type SaveLineItemOptionsMutationVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  export_invoice_sub_line_items: Types.Scalars['String'];
  include_zero_quantity_sub_line_items: Types.Scalars['String'];
  stripe_invoice_quantity_always_string: Types.Scalars['String'];
}>;


export type SaveLineItemOptionsMutation = { __typename?: 'Mutation', exportSubLineItem: { __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string } | null, includeZeroQuantitySubLineItems: { __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string } | null, stripeQuantityAlwaysString: { __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string } | null };


export const GetLineItemOptionsDocument = gql`
    query GetLineItemOptions($environment_type: EnvironmentTypeEnum_enum!) {
  exportSubLineItemConfig: ClientConfig(
    where: {key: {_eq: export_invoice_sub_line_items}, environment_type: {_eq: $environment_type}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
  includeZeroQuantitySubLineItems: ClientConfig(
    where: {key: {_eq: include_zero_quantity_sub_line_items}, environment_type: {_eq: $environment_type}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
  stripeQuantityAlwaysString: ClientConfig(
    where: {key: {_eq: stripe_invoice_quantity_always_string}, environment_type: {_eq: $environment_type}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetLineItemOptionsQuery__
 *
 * To run a query within a React component, call `useGetLineItemOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLineItemOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLineItemOptionsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetLineItemOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetLineItemOptionsQuery, GetLineItemOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLineItemOptionsQuery, GetLineItemOptionsQueryVariables>(GetLineItemOptionsDocument, options);
      }
export function useGetLineItemOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLineItemOptionsQuery, GetLineItemOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLineItemOptionsQuery, GetLineItemOptionsQueryVariables>(GetLineItemOptionsDocument, options);
        }
export type GetLineItemOptionsQueryHookResult = ReturnType<typeof useGetLineItemOptionsQuery>;
export type GetLineItemOptionsLazyQueryHookResult = ReturnType<typeof useGetLineItemOptionsLazyQuery>;
export type GetLineItemOptionsQueryResult = Apollo.QueryResult<GetLineItemOptionsQuery, GetLineItemOptionsQueryVariables>;
export const SaveLineItemOptionsDocument = gql`
    mutation SaveLineItemOptions($environment_type: EnvironmentTypeEnum_enum!, $export_invoice_sub_line_items: String!, $include_zero_quantity_sub_line_items: String!, $stripe_invoice_quantity_always_string: String!) {
  exportSubLineItem: insert_ClientConfig_one(
    object: {key: export_invoice_sub_line_items, value: $export_invoice_sub_line_items}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value, where: {key: {_eq: export_invoice_sub_line_items}, environment_type: {_eq: $environment_type}}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
  includeZeroQuantitySubLineItems: insert_ClientConfig_one(
    object: {key: include_zero_quantity_sub_line_items, value: $include_zero_quantity_sub_line_items}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value, where: {key: {_eq: include_zero_quantity_sub_line_items}, environment_type: {_eq: $environment_type}}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
  stripeQuantityAlwaysString: insert_ClientConfig_one(
    object: {key: stripe_invoice_quantity_always_string, value: $stripe_invoice_quantity_always_string}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value, where: {key: {_eq: stripe_invoice_quantity_always_string}, environment_type: {_eq: $environment_type}}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
}
    `;
export type SaveLineItemOptionsMutationFn = Apollo.MutationFunction<SaveLineItemOptionsMutation, SaveLineItemOptionsMutationVariables>;

/**
 * __useSaveLineItemOptionsMutation__
 *
 * To run a mutation, you first call `useSaveLineItemOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLineItemOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLineItemOptionsMutation, { data, loading, error }] = useSaveLineItemOptionsMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      export_invoice_sub_line_items: // value for 'export_invoice_sub_line_items'
 *      include_zero_quantity_sub_line_items: // value for 'include_zero_quantity_sub_line_items'
 *      stripe_invoice_quantity_always_string: // value for 'stripe_invoice_quantity_always_string'
 *   },
 * });
 */
export function useSaveLineItemOptionsMutation(baseOptions?: Apollo.MutationHookOptions<SaveLineItemOptionsMutation, SaveLineItemOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLineItemOptionsMutation, SaveLineItemOptionsMutationVariables>(SaveLineItemOptionsDocument, options);
      }
export type SaveLineItemOptionsMutationHookResult = ReturnType<typeof useSaveLineItemOptionsMutation>;
export type SaveLineItemOptionsMutationResult = Apollo.MutationResult<SaveLineItemOptionsMutation>;
export type SaveLineItemOptionsMutationOptions = Apollo.BaseMutationOptions<SaveLineItemOptionsMutation, SaveLineItemOptionsMutationVariables>;