import React from "react";
import classnames from "classnames";
import { Subtitle, Icon, IconName } from "design-system";

import styles from "./index.module.less";

import { EventDetailsQuery } from "../../queries.graphql";
import { useFeatureFlag } from "lib/launchdarkly";
type Event = EventDetailsQuery["events"][0];

type StepState = "success" | "error" | "warning" | "neutral";

interface StepProps {
  state: StepState;
  heading: string;
  icon: IconName;
  entries: string[];
}

const Step: React.FC<StepProps> = (props) => {
  const { icon, heading } = props;

  return (
    <div className={styles.eventTimelineStepRow}>
      <div className={styles.lineContainer}>
        <div className={styles.lineTop} />
        <div className={styles.lineBottom} />
      </div>
      <span className={classnames(styles.dot, styles[props.state])} />
      <div className={styles.eventTimelineStepColumn}>
        <Subtitle
          className={classnames(styles.rowTitle, styles[props.state])}
          level={2}
        >
          {heading}
        </Subtitle>
        {props.entries.map((title, i) => (
          <div
            className={classnames(styles.stepBlock, styles[props.state])}
            key={i}
          >
            <Icon icon={icon} className={styles.stepIcon} />
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

type SharedStepProps = {
  event: Event;
};

const IngestStep = () => {
  return (
    <Step
      state="success"
      heading="Event ingested"
      icon="codeWorking"
      entries={["/INGEST"]}
    />
  );
};

const BillableMetricStep: React.FC<SharedStepProps> = (props) => {
  let heading = "Billable metric not matched";
  let entries = ["NO BILLABLE METRIC MATCHED"];
  let stepState: StepState = "error";
  if (props.event?.billable_metrics?.length) {
    heading = "Billable metric matched";
    entries = props.event?.billable_metrics.map(({ name }) => name);
    stepState = "success";
  }

  return (
    <Step
      state={stepState}
      heading={heading}
      icon="pricetags"
      entries={entries}
    />
  );
};

const SeatMetricStep: React.FC<SharedStepProps> = (props) => {
  let heading = "Seat metric not matched";
  let entries = ["NO SEAT MATCHED"];
  let stepState: StepState = "error";
  if (props.event?.seat_metrics?.length) {
    heading = "Seat metric matched";
    entries = props.event?.seat_metrics.map(({ name }) => name);
    stepState = "success";
  }

  return (
    <Step
      state={stepState}
      heading={heading}
      icon="pricetags"
      entries={entries}
    />
  );
};

const CustomerStep: React.FC<SharedStepProps> = (props) => {
  const { event } = props;
  let heading = "Customer not matched";
  let entries = ["NO CUSTOMER FOUND"];
  let stepState: StepState = "error";
  if (event?.customer) {
    heading = "Customer matched";
    entries = [event.customer.name];
    stepState = "success";
  }
  return (
    <Step
      state={stepState}
      heading={heading}
      icon="personCircle"
      entries={entries}
    />
  );
};

const DuplicateStep: React.FC = () => {
  return (
    <Step
      state="error"
      heading="Event was a duplicate"
      icon="codeWorking"
      entries={[]}
    />
  );
};

type EventTimelineProps = {
  event: Event;
};

export const EventTimeline: React.FC<EventTimelineProps> = ({ event }) => {
  const seatsEnabled = useFeatureFlag("seats", false);
  return (
    <div className={styles.timeline}>
      <IngestStep />
      {event.is_duplicate ? (
        <DuplicateStep />
      ) : (
        <>
          <BillableMetricStep event={event} />
          {seatsEnabled ? <SeatMetricStep event={event} /> : null}
          <CustomerStep event={event} />
        </>
      )}
    </div>
  );
};
