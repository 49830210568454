/* c265fd5f26a50a6b85a6a5d6008726350902271e
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePrequelAuthTokenMutationVariables = Types.Exact<{
  applicationOrigin: Types.Scalars['String'];
}>;


export type GeneratePrequelAuthTokenMutation = { __typename?: 'Mutation', generate_prequel_auth_token: { __typename?: 'PrequelAuthTokenResponse', token_string: string } };

export type GetClientQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string }> };

export type GenerateWorkatoSsoUrlMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateWorkatoSsoUrlMutation = { __typename?: 'Mutation', generate_workato_sso_url: { __typename?: 'WorkatoSSOUrlResponse', sso_url: string } };

export type GetClientConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientConfigQuery = { __typename?: 'Query', is_delta_stream_enabled: boolean, is_customer_billing_provider_configuration_enabled: boolean };

export type GetDeliveryMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDeliveryMethodsQuery = { __typename?: 'Query', list_delivery_methods: { __typename?: 'ListDeliveryMethodsResponse', delivery_methods: Array<{ __typename?: 'BillingProviderDeliveryMethod', id: string, billing_provider: Types.BillingProviderEnum_Enum, delivery_method: Types.BillingProviderDeliveryMethod_Enum }> } };

export type GetSalesforceWorkspaceQueryVariables = Types.Exact<{
  destinationType: Types.CensusDestinationTypeEnum;
}>;


export type GetSalesforceWorkspaceQuery = { __typename?: 'Query', get_census_workspace: { __typename?: 'CensusWorkspace', id: string, is_provisioned: boolean, created_at: string } | null };


export const GeneratePrequelAuthTokenDocument = gql`
    mutation GeneratePrequelAuthToken($applicationOrigin: String!) {
  generate_prequel_auth_token(application_origin: $applicationOrigin) {
    token_string
  }
}
    `;
export type GeneratePrequelAuthTokenMutationFn = Apollo.MutationFunction<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;

/**
 * __useGeneratePrequelAuthTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePrequelAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePrequelAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePrequelAuthTokenMutation, { data, loading, error }] = useGeneratePrequelAuthTokenMutation({
 *   variables: {
 *      applicationOrigin: // value for 'applicationOrigin'
 *   },
 * });
 */
export function useGeneratePrequelAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>(GeneratePrequelAuthTokenDocument, options);
      }
export type GeneratePrequelAuthTokenMutationHookResult = ReturnType<typeof useGeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationResult = Apollo.MutationResult<GeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationOptions = Apollo.BaseMutationOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;
export const GetClientDocument = gql`
    query GetClient {
  Client {
    id
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GenerateWorkatoSsoUrlDocument = gql`
    mutation GenerateWorkatoSSOUrl {
  generate_workato_sso_url {
    sso_url
  }
}
    `;
export type GenerateWorkatoSsoUrlMutationFn = Apollo.MutationFunction<GenerateWorkatoSsoUrlMutation, GenerateWorkatoSsoUrlMutationVariables>;

/**
 * __useGenerateWorkatoSsoUrlMutation__
 *
 * To run a mutation, you first call `useGenerateWorkatoSsoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWorkatoSsoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWorkatoSsoUrlMutation, { data, loading, error }] = useGenerateWorkatoSsoUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateWorkatoSsoUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWorkatoSsoUrlMutation, GenerateWorkatoSsoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWorkatoSsoUrlMutation, GenerateWorkatoSsoUrlMutationVariables>(GenerateWorkatoSsoUrlDocument, options);
      }
export type GenerateWorkatoSsoUrlMutationHookResult = ReturnType<typeof useGenerateWorkatoSsoUrlMutation>;
export type GenerateWorkatoSsoUrlMutationResult = Apollo.MutationResult<GenerateWorkatoSsoUrlMutation>;
export type GenerateWorkatoSsoUrlMutationOptions = Apollo.BaseMutationOptions<GenerateWorkatoSsoUrlMutation, GenerateWorkatoSsoUrlMutationVariables>;
export const GetClientConfigDocument = gql`
    query GetClientConfig {
  is_delta_stream_enabled
  is_customer_billing_provider_configuration_enabled
}
    `;

/**
 * __useGetClientConfigQuery__
 *
 * To run a query within a React component, call `useGetClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
      }
export function useGetClientConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
        }
export type GetClientConfigQueryHookResult = ReturnType<typeof useGetClientConfigQuery>;
export type GetClientConfigLazyQueryHookResult = ReturnType<typeof useGetClientConfigLazyQuery>;
export type GetClientConfigQueryResult = Apollo.QueryResult<GetClientConfigQuery, GetClientConfigQueryVariables>;
export const GetDeliveryMethodsDocument = gql`
    query GetDeliveryMethods {
  list_delivery_methods {
    delivery_methods {
      id
      billing_provider
      delivery_method
      __environment_type: environment_type
    }
  }
}
    `;

/**
 * __useGetDeliveryMethodsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveryMethodsQuery, GetDeliveryMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryMethodsQuery, GetDeliveryMethodsQueryVariables>(GetDeliveryMethodsDocument, options);
      }
export function useGetDeliveryMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryMethodsQuery, GetDeliveryMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryMethodsQuery, GetDeliveryMethodsQueryVariables>(GetDeliveryMethodsDocument, options);
        }
export type GetDeliveryMethodsQueryHookResult = ReturnType<typeof useGetDeliveryMethodsQuery>;
export type GetDeliveryMethodsLazyQueryHookResult = ReturnType<typeof useGetDeliveryMethodsLazyQuery>;
export type GetDeliveryMethodsQueryResult = Apollo.QueryResult<GetDeliveryMethodsQuery, GetDeliveryMethodsQueryVariables>;
export const GetSalesforceWorkspaceDocument = gql`
    query GetSalesforceWorkspace($destinationType: CensusDestinationTypeEnum!) {
  get_census_workspace(destination_type: $destinationType) {
    id
    is_provisioned
    created_at
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetSalesforceWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetSalesforceWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceWorkspaceQuery({
 *   variables: {
 *      destinationType: // value for 'destinationType'
 *   },
 * });
 */
export function useGetSalesforceWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<GetSalesforceWorkspaceQuery, GetSalesforceWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesforceWorkspaceQuery, GetSalesforceWorkspaceQueryVariables>(GetSalesforceWorkspaceDocument, options);
      }
export function useGetSalesforceWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesforceWorkspaceQuery, GetSalesforceWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesforceWorkspaceQuery, GetSalesforceWorkspaceQueryVariables>(GetSalesforceWorkspaceDocument, options);
        }
export type GetSalesforceWorkspaceQueryHookResult = ReturnType<typeof useGetSalesforceWorkspaceQuery>;
export type GetSalesforceWorkspaceLazyQueryHookResult = ReturnType<typeof useGetSalesforceWorkspaceLazyQuery>;
export type GetSalesforceWorkspaceQueryResult = Apollo.QueryResult<GetSalesforceWorkspaceQuery, GetSalesforceWorkspaceQueryVariables>;