import { Schema } from "../Schema";
import { Refine } from "pages/Contracts/Customer/Contracts/Create/Schema";
import { FormController } from "lib/FormController";

export const useRateController = FormController.createHook(
  Schema.Rate.superRefine(Refine.startingAtAndEndingBefore),
  {
    init(snapshotProps?: Partial<Schema.Types.Rate>) {
      return snapshotProps || { entitled: "enable" };
    },
  },
);
export type RateCtrl = ReturnType<typeof useRateController>;
