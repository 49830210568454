/* 2f18338444314ed9502d7aab110b7c6b81611722
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAwsMarketplaceForCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  aws_customer_id: Types.Scalars['String'];
  aws_product_code: Types.Scalars['String'];
  aws_region: Types.Scalars['String'];
}>;


export type AddAwsMarketplaceForCustomerMutation = { __typename?: 'Mutation', add_aws_marketplace_to_customer: { __typename?: 'AddMarketplaceToCustomerMutationResponse', billingProviderCustomer: { __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }, customerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, key: string, value: string }> } };


export const AddAwsMarketplaceForCustomerDocument = gql`
    mutation AddAWSMarketplaceForCustomer($customer_id: uuid!, $aws_customer_id: String!, $aws_product_code: String!, $aws_region: String!) {
  add_aws_marketplace_to_customer(
    customer_id: $customer_id
    aws_customer_id: $aws_customer_id
    aws_product_code: $aws_product_code
    aws_region: $aws_region
  ) {
    billingProviderCustomer {
      billing_provider_customer_id
    }
    customerConfigs {
      id
      key
      value
    }
  }
}
    `;
export type AddAwsMarketplaceForCustomerMutationFn = Apollo.MutationFunction<AddAwsMarketplaceForCustomerMutation, AddAwsMarketplaceForCustomerMutationVariables>;

/**
 * __useAddAwsMarketplaceForCustomerMutation__
 *
 * To run a mutation, you first call `useAddAwsMarketplaceForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAwsMarketplaceForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAwsMarketplaceForCustomerMutation, { data, loading, error }] = useAddAwsMarketplaceForCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      aws_customer_id: // value for 'aws_customer_id'
 *      aws_product_code: // value for 'aws_product_code'
 *      aws_region: // value for 'aws_region'
 *   },
 * });
 */
export function useAddAwsMarketplaceForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddAwsMarketplaceForCustomerMutation, AddAwsMarketplaceForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAwsMarketplaceForCustomerMutation, AddAwsMarketplaceForCustomerMutationVariables>(AddAwsMarketplaceForCustomerDocument, options);
      }
export type AddAwsMarketplaceForCustomerMutationHookResult = ReturnType<typeof useAddAwsMarketplaceForCustomerMutation>;
export type AddAwsMarketplaceForCustomerMutationResult = Apollo.MutationResult<AddAwsMarketplaceForCustomerMutation>;
export type AddAwsMarketplaceForCustomerMutationOptions = Apollo.BaseMutationOptions<AddAwsMarketplaceForCustomerMutation, AddAwsMarketplaceForCustomerMutationVariables>;