/* 59ccfaa29fcb3366c8a34532a02fcf7ac484de5c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPlanToCustomerDataQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  customer_id: Types.Scalars['uuid'];
}>;


export type AddPlanToCustomerDataQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, name: string, end_date_of_last_finalized_invoice: string | null, CustomerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, value: string }>, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }> } | null, BillingProviderToken: Array<{ __typename?: 'BillingProviderToken', billing_provider: Types.BillingProviderEnum_Enum }>, CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }>, plans: Array<{ __typename?: 'Plan', name: string, id: string, description: string, created_at: string, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } }>, CustomerPlan: Array<{ __typename?: 'CustomerPlan', id: string, start_date: string, cancellation_date: string | null, net_payment_terms_days: number | null }>, NetPaymentTermsDaysDefault: Array<{ __typename?: 'ClientConfig', id: string, value: string }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type AddPlanToCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  plan_id: Types.Scalars['uuid'];
  start_date: Types.Scalars['timestamptz'];
  end_date?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  trial_spec?: Types.InputMaybe<Types.TrialSpecInput>;
  custom_pricing?: Types.InputMaybe<Types.CustomPricingInput>;
  net_payment_terms_days?: Types.InputMaybe<Types.Scalars['Int']>;
  credit_type_conversion_adjustments?: Types.InputMaybe<Array<Types.CreditTypeConversionInput> | Types.CreditTypeConversionInput>;
}>;


export type AddPlanToCustomerMutation = { __typename?: 'Mutation', add_plan_to_customer: { __typename?: 'CustomerPlan', id: string } };

export type AddCustomerBillingMutationVariables = Types.Exact<{
  stripeConfig?: Array<Types.BillingProviderCustomer_Insert_Input> | Types.BillingProviderCustomer_Insert_Input;
  stripeConfigCollectionMethod?: Array<Types.CustomerConfig_Insert_Input> | Types.CustomerConfig_Insert_Input;
}>;


export type AddCustomerBillingMutation = { __typename?: 'Mutation', insert_BillingProviderCustomer: { __typename?: 'BillingProviderCustomer_mutation_response', returning: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }> } | null, insert_CustomerConfig: { __typename?: 'CustomerConfig_mutation_response', returning: Array<{ __typename?: 'CustomerConfig', id: string }> } | null };

export type GetStripeCustomerQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  stripe_customer_id: Types.Scalars['String'];
}>;


export type GetStripeCustomerQuery = { __typename?: 'Query', stripeCustomer: { __typename?: 'StripeCustomer', name: string } | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const AddPlanToCustomerDataDocument = gql`
    query AddPlanToCustomerData($environment_type: EnvironmentTypeEnum_enum!, $customer_id: uuid!) {
  Customer_by_pk(id: $customer_id) {
    id
    name
    CustomerConfigs(where: {key: {_eq: "stripe_collection_method"}}) {
      id
      value
    }
    BillingProviderCustomers(where: {billing_provider: {_eq: STRIPE}}) {
      billing_provider_customer_id
    }
    end_date_of_last_finalized_invoice
    __environment_type: environment_type
  }
  BillingProviderToken(
    where: {billing_provider: {_eq: STRIPE}, environment_type: {_eq: $environment_type}}
  ) {
    billing_provider
    __environment_type: environment_type
  }
  CreditType(
    where: {archived_at: {_is_null: true}, _or: [{environment_type: {_eq: $environment_type}}, {environment_type: {_is_null: true}}]}
  ) {
    ...CreditType
    __environment_type: environment_type
  }
  plans(environment_type: $environment_type, plan_type: ACTIVE_ONLY) {
    name
    id
    description
    created_at
    customer_count: customer_count(status: ACTIVE)
    Actor {
      name
      id
      deprecated_at
    }
    PricedProducts_aggregate {
      aggregate {
        count
      }
    }
    __environment_type: environment_type
  }
  CustomerPlan(
    where: {deleted_at: {_is_null: true}, customer_id: {_eq: $customer_id}}
  ) {
    id
    start_date
    cancellation_date
    net_payment_terms_days
  }
  NetPaymentTermsDaysDefault: ClientConfig(
    where: {key: {_eq: invoice_days_until_due}, environment_type: {_eq: $environment_type}}
  ) {
    id
    value
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useAddPlanToCustomerDataQuery__
 *
 * To run a query within a React component, call `useAddPlanToCustomerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanToCustomerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanToCustomerDataQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useAddPlanToCustomerDataQuery(baseOptions: Apollo.QueryHookOptions<AddPlanToCustomerDataQuery, AddPlanToCustomerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanToCustomerDataQuery, AddPlanToCustomerDataQueryVariables>(AddPlanToCustomerDataDocument, options);
      }
export function useAddPlanToCustomerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanToCustomerDataQuery, AddPlanToCustomerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanToCustomerDataQuery, AddPlanToCustomerDataQueryVariables>(AddPlanToCustomerDataDocument, options);
        }
export type AddPlanToCustomerDataQueryHookResult = ReturnType<typeof useAddPlanToCustomerDataQuery>;
export type AddPlanToCustomerDataLazyQueryHookResult = ReturnType<typeof useAddPlanToCustomerDataLazyQuery>;
export type AddPlanToCustomerDataQueryResult = Apollo.QueryResult<AddPlanToCustomerDataQuery, AddPlanToCustomerDataQueryVariables>;
export const AddPlanToCustomerDocument = gql`
    mutation AddPlanToCustomer($customer_id: uuid!, $plan_id: uuid!, $start_date: timestamptz!, $end_date: timestamptz, $trial_spec: TrialSpecInput, $custom_pricing: CustomPricingInput, $net_payment_terms_days: Int, $credit_type_conversion_adjustments: [CreditTypeConversionInput!]) {
  add_plan_to_customer(
    customer_id: $customer_id
    plan_id: $plan_id
    start_date: $start_date
    cancellation_date: $end_date
    trial_spec: $trial_spec
    custom_pricing: $custom_pricing
    net_payment_terms_days: $net_payment_terms_days
    credit_type_conversion_adjustments: $credit_type_conversion_adjustments
  ) {
    id
  }
}
    `;
export type AddPlanToCustomerMutationFn = Apollo.MutationFunction<AddPlanToCustomerMutation, AddPlanToCustomerMutationVariables>;

/**
 * __useAddPlanToCustomerMutation__
 *
 * To run a mutation, you first call `useAddPlanToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanToCustomerMutation, { data, loading, error }] = useAddPlanToCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      plan_id: // value for 'plan_id'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      trial_spec: // value for 'trial_spec'
 *      custom_pricing: // value for 'custom_pricing'
 *      net_payment_terms_days: // value for 'net_payment_terms_days'
 *      credit_type_conversion_adjustments: // value for 'credit_type_conversion_adjustments'
 *   },
 * });
 */
export function useAddPlanToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanToCustomerMutation, AddPlanToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanToCustomerMutation, AddPlanToCustomerMutationVariables>(AddPlanToCustomerDocument, options);
      }
export type AddPlanToCustomerMutationHookResult = ReturnType<typeof useAddPlanToCustomerMutation>;
export type AddPlanToCustomerMutationResult = Apollo.MutationResult<AddPlanToCustomerMutation>;
export type AddPlanToCustomerMutationOptions = Apollo.BaseMutationOptions<AddPlanToCustomerMutation, AddPlanToCustomerMutationVariables>;
export const AddCustomerBillingDocument = gql`
    mutation AddCustomerBilling($stripeConfig: [BillingProviderCustomer_insert_input!]! = [], $stripeConfigCollectionMethod: [CustomerConfig_insert_input!]! = []) {
  insert_BillingProviderCustomer(
    objects: $stripeConfig
    on_conflict: {update_columns: [billing_provider_customer_id], constraint: BillingProviderCustomer_pkey}
  ) {
    returning {
      billing_provider_customer_id
    }
  }
  insert_CustomerConfig(
    objects: $stripeConfigCollectionMethod
    on_conflict: {update_columns: [value], constraint: CustomerConfig_client_id_customer_id_key_key}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddCustomerBillingMutationFn = Apollo.MutationFunction<AddCustomerBillingMutation, AddCustomerBillingMutationVariables>;

/**
 * __useAddCustomerBillingMutation__
 *
 * To run a mutation, you first call `useAddCustomerBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerBillingMutation, { data, loading, error }] = useAddCustomerBillingMutation({
 *   variables: {
 *      stripeConfig: // value for 'stripeConfig'
 *      stripeConfigCollectionMethod: // value for 'stripeConfigCollectionMethod'
 *   },
 * });
 */
export function useAddCustomerBillingMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerBillingMutation, AddCustomerBillingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerBillingMutation, AddCustomerBillingMutationVariables>(AddCustomerBillingDocument, options);
      }
export type AddCustomerBillingMutationHookResult = ReturnType<typeof useAddCustomerBillingMutation>;
export type AddCustomerBillingMutationResult = Apollo.MutationResult<AddCustomerBillingMutation>;
export type AddCustomerBillingMutationOptions = Apollo.BaseMutationOptions<AddCustomerBillingMutation, AddCustomerBillingMutationVariables>;
export const GetStripeCustomerDocument = gql`
    query GetStripeCustomer($environment_type: EnvironmentTypeEnum_enum!, $stripe_customer_id: String!) {
  stripeCustomer(environment_type: $environment_type, id: $stripe_customer_id) {
    name
  }
}
    `;

/**
 * __useGetStripeCustomerQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      stripe_customer_id: // value for 'stripe_customer_id'
 *   },
 * });
 */
export function useGetStripeCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
      }
export function useGetStripeCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
        }
export type GetStripeCustomerQueryHookResult = ReturnType<typeof useGetStripeCustomerQuery>;
export type GetStripeCustomerLazyQueryHookResult = ReturnType<typeof useGetStripeCustomerLazyQuery>;
export type GetStripeCustomerQueryResult = Apollo.QueryResult<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>;