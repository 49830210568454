/* b6cc432ee7d390bdd648e59fc11d75f3e68b1b67
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AlertRulesQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AlertRulesQuery = { __typename?: 'Query', alerts: Array<{ __typename?: 'Alert', id: string, name: string, threshold: string, alert_type: Types.AlertTypeEnum_Enum, webhooks_enabled: boolean, filters: object | null, Customer: { __typename?: 'Customer', id: string, name: string } | null, Plan: { __typename?: 'Plan', id: string, name: string } | null, CreditType: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null, customer_alerts: Array<{ __typename?: 'CustomerAlertComposite', id: string | null, customer_status: Types.CustomerAlertCompositeStatusEnum | null }> }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const AlertRulesDocument = gql`
    query AlertRules($environment_type: EnvironmentTypeEnum_enum!, $search: String) {
  alerts(
    environment_type: $environment_type
    types: [ACTIVE]
    search: $search
    limit: 100
  ) {
    id
    name
    threshold
    alert_type
    webhooks_enabled
    filters
    Customer {
      id
      name
      __environment_type: environment_type
    }
    Plan {
      id
      name
      __environment_type: environment_type
    }
    CreditType {
      ...CreditType
      __environment_type: environment_type
    }
    BillableMetric {
      id
      name
      __environment_type: environment_type
    }
    customer_alerts(
      sort: {limit: 0, offset: 0, order_by: ALERT_STATUS, ascending: false}
    ) {
      id
      customer_status
    }
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useAlertRulesQuery__
 *
 * To run a query within a React component, call `useAlertRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertRulesQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAlertRulesQuery(baseOptions: Apollo.QueryHookOptions<AlertRulesQuery, AlertRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertRulesQuery, AlertRulesQueryVariables>(AlertRulesDocument, options);
      }
export function useAlertRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertRulesQuery, AlertRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertRulesQuery, AlertRulesQueryVariables>(AlertRulesDocument, options);
        }
export type AlertRulesQueryHookResult = ReturnType<typeof useAlertRulesQuery>;
export type AlertRulesLazyQueryHookResult = ReturnType<typeof useAlertRulesLazyQuery>;
export type AlertRulesQueryResult = Apollo.QueryResult<AlertRulesQuery, AlertRulesQueryVariables>;