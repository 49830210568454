import React, { useEffect } from "react";
import { Schema } from "../Schema";
import { RateCtrl, useRateController } from "./RateCtrl";
import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "lib/credits";
import { Select } from "design-system";
import { CreditType } from "types/credit-types";
import { findCreditType } from "pages/Contracts/lib/CreditTypes";

const useFlatPriceController = useRateController.child(Schema.FlatPrice, {
  read(parent) {
    const price = parent.get("price");
    return price?.type === "flat" ? price : undefined;
  },
  write: (child) => ({
    price: {
      type: "flat",
      price: child.get("price"),
    },
  }),
});

interface Props {
  parent: RateCtrl;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
}

export const RateFlat: React.FC<Props> = (props) => {
  const allCreditTypes = [...props.fiatCreditTypes, ...props.customCreditTypes];
  const flatCtrl = useFlatPriceController(props.parent);
  const selectedCreditTypeId = props.parent.get("creditType")?.id;

  useEffect(() => {
    const shouldUpdateToSinglePossibleCreditType =
      allCreditTypes.length === 1 &&
      selectedCreditTypeId !== allCreditTypes[0].id;
    if (shouldUpdateToSinglePossibleCreditType || !selectedCreditTypeId) {
      props.parent.update({
        creditType: allCreditTypes[0],
      });
    }
  }, [props.parent.get("productId")]);

  return (
    <>
      <Select
        name="Pricing unit"
        placeholder=""
        value={props.parent.get("creditType")?.id ?? allCreditTypes[0].id}
        options={[
          {
            label: "Currency",
            options: props.fiatCreditTypes.map((ct) => ({
              label: displayCreditTypeName(ct),
              value: ct.id,
            })),
          },
          {
            label: "Custom pricing unit",
            options: props.customCreditTypes.map((ct) => ({
              label: displayCreditTypeName(ct),
              value: ct.id,
            })),
          },
        ]}
        onChange={(v) => {
          if (v) {
            const updatedCreditType = findCreditType(v, allCreditTypes);
            props.parent.update({
              creditType: updatedCreditType,
            });
            if (selectedCreditTypeId) {
              flatCtrl.update({
                price: undefined,
              });
            }
          }
        }}
      />
      <CreditInput
        {...flatCtrl.props.CreditInput("price", {
          name: "Rate",
          placeholder: "123.45",
          creditType:
            allCreditTypes.find((ct) => ct.id === selectedCreditTypeId) ??
            USD_CREDIT_TYPE,
          allowZeroAmounts: true,
        })}
      />
    </>
  );
};
