import React from "react";
import "/src/tenaissance/tenaissance.css";
import { Breadcrumbs } from "../../../lib/breadcrumbs";
import { PageHeaderBreadcrumbs } from "../Breadcrumbs";
import { IconButton } from "../IconButton";
import { HorizontalTabs, TabProps } from "../HorizontalTabs";
import { twMerge } from "../../twMerge";
import { useNavigate } from "../../../lib/useNavigate";

export interface PageHeaderProps {
  /** Used to navigate to previous pages */
  breadcrumbs?: Breadcrumbs;
  /** Page title */
  title: string;
  /** Buttons displayed inline with title */
  actions?: React.ReactNode;
  /** Tabs used to navigate within page */
  tabs?: TabProps[];
  /** Base path */
  basePath?: string;
}

/**
 * Page headers provide content and actions related to the current screen.
 * Designed to be used at the top of a page. Supports navigation via breadcrumbs and tabs, and arbitrary actions.
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbs,
  title,
  actions,
  tabs,
  basePath,
}) => {
  const navigate = useNavigate();
  const backButtonBreadcrumb = breadcrumbs?.backBreadcrumb();
  return (
    <div
      className={twMerge(
        "px-xl flex flex-col",
        tabs || breadcrumbs ? "pt-xl" : "h-7xl border-b",
      )}
    >
      <div className="flex flex-1 items-center gap-8">
        {backButtonBreadcrumb && (
          <IconButton
            icon="flipBackward"
            linkTo={backButtonBreadcrumb.routePath}
            onClick={
              backButtonBreadcrumb.routePath ? undefined : () => navigate(-1)
            }
            theme="secondary"
            className="h-[50px] w-[50px] p-[15px]"
          />
        )}
        <div className="flex-1">
          {breadcrumbs && <PageHeaderBreadcrumbs breadcrumbs={breadcrumbs} />}
          <h5 className="text-core-slate text-display-xs font-semibold">
            {title}
          </h5>
        </div>
        {actions && (
          <div data-testid="header-action" className="gap-md min-h-4xl flex">
            {actions}
          </div>
        )}
      </div>
      {tabs && (
        <div className="mt-lg">
          <HorizontalTabs tabs={tabs} basePath={basePath} />
        </div>
      )}
    </div>
  );
};
