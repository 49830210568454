import React, { useMemo, useState } from "react";
import { Button } from "tenaissance/components/Button";
import { useLocation, Outlet } from "react-router-dom";
import { Takeover } from "tenaissance/components/Takeover";
import { useSnackbar } from "components/Snackbar";
import { useCreateRateCardMutation } from "./data.graphql";
import { useNavigate } from "lib/useNavigate";

import { reportToSentry } from "lib/errors/sentry";
import {
  formAliasesAsGraphqlAliases,
  formCreditTypeConversionsAsGraphqlCreditTypeConversions,
  formRatesAsGraphqlRates,
} from "./utils";
import { RateCardContext } from "./RateCardContext";
import { USD_CREDIT_ID } from "lib/credits";
import {
  RateCardCreatedModal,
  shouldShowRateCardCreatedModal,
} from "./RateCardCreatedModal";
import { useUIMode } from "../../../../lib/useUIMode";
import {
  RateCardCreateLandingPage,
  shouldShowPreviewPage,
} from "./LandingPage";

const RateCardCreate: React.FC = () => {
  const [showRateCreateModal, setShowRateCreateModal] = React.useState(false);
  const [showPreviewPage, setShowPreviewPage] = useState(shouldShowPreviewPage);
  const location = useLocation();

  const navigate = useNavigate();

  const {
    clearSnapshot,
    loading,
    selectedProducts,
    name,
    description,
    aliases,
    dimensionalProducts,
    rates,
    fiatCreditType,
    creditTypeConversions,
    hasValidRates,
  } = RateCardContext.useContainer();
  const { newUIEnabled } = useUIMode();
  const urlPrefix = useMemo(
    () =>
      newUIEnabled
        ? "/offering/rate-cards/new"
        : "/contract-pricing/rate-cards/create",
    [newUIEnabled],
  );
  const hasDimensionalPricingRates = dimensionalProducts.length > 0;
  const getCurrentStepName = () => {
    return location.pathname.split(`${urlPrefix}/`)[1];
  };

  const isFirstStep = !getCurrentStepName();
  const isLastStep = getCurrentStepName() === "rate-products";

  const pushMessage = useSnackbar();

  const [createRateCardMutation, createRateCardResult] =
    useCreateRateCardMutation();

  const backToRateCardList = () => {
    clearSnapshot();
    navigate(
      newUIEnabled ? `/offering/rate-cards` : `/contract-pricing/rate-cards`,
    );
  };

  const onSubmit = async () => {
    try {
      // multiply USD rates by 100 to convert to cents
      const convertedRates = [
        rates.usageRates.filter((r) => !r.isRemovedSubrate),
        rates.subscriptionRates,
        rates.compositeRates,
      ]
        .flat()
        .map((r) => {
          if (r.creditType?.id === USD_CREDIT_ID) {
            switch (r.price.type) {
              case "flat":
                return {
                  ...r,
                  price: {
                    ...r.price,
                    price: r.price.price * 100,
                  },
                };
              case "tiered":
                return {
                  ...r,
                  price: {
                    ...r.price,
                    tiers: r.price.tiers.map((tier) => ({
                      ...tier,
                      unitPrice: tier.unitPrice * 100,
                    })),
                  },
                };
              case "subscription":
                return {
                  ...r,
                  price: {
                    ...r.price,
                    price: r.price.price * 100,
                  },
                };
              default:
                return r;
            }
          } else {
            return r;
          }
        });

      const fiatCreditTypeId = fiatCreditType.id;
      const convertedCreditTypeConversions = creditTypeConversions.map(
        (cpu) => {
          if (fiatCreditTypeId === USD_CREDIT_ID) {
            return {
              ...cpu,
              fiat_per_custom_credit: cpu.fiat_per_custom_credit * 100,
            };
          } else {
            return cpu;
          }
        },
      );
      const result = await createRateCardMutation({
        variables: {
          name: name.trim(),
          description: description?.trim(),
          rates: formRatesAsGraphqlRates(convertedRates),
          aliases: formAliasesAsGraphqlAliases(aliases ?? []),
          fiatCreditTypeId,
          creditTypeConversions:
            formCreditTypeConversionsAsGraphqlCreditTypeConversions(
              convertedCreditTypeConversions,
            ),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
        },
      });
      const id = result.data?.create_rate_card?.id;

      if (id) {
        if (shouldShowRateCardCreatedModal()) {
          setShowRateCreateModal(true);
        } else {
          pushMessage({
            type: "success",
            content: "Rate card created successfully",
          });
          clearSnapshot();
          navigate(
            newUIEnabled
              ? `/offering/rate-cards/${id}`
              : `/contract-pricing/rate-cards/${id}`,
          );
        }
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to create new rate card: ${e}`,
        type: "error",
      });
    }
  };

  let title: string;
  let nextStepDisabled: boolean = loading;
  if (isLastStep) {
    nextStepDisabled =
      nextStepDisabled || !hasValidRates || createRateCardResult.loading;
    title = "Rate your products";
  } else if (isFirstStep) {
    title = "Create a rate card";
    nextStepDisabled =
      nextStepDisabled || selectedProducts.length === 0 || !name;
  } else {
    title = "Define dimensional pricing";
    nextStepDisabled =
      nextStepDisabled ||
      dimensionalProducts.some((p) =>
        p.pricingGroupKeyValues.some((k) => !k.values.length),
      );
  }

  return (
    <Takeover
      title={title}
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={() => backToRateCardList()}
      footerLeadingButton={
        <Button
          onClick={() => {
            if (isLastStep && hasDimensionalPricingRates) {
              navigate(`${urlPrefix}/dimensional-pricing`);
            } else if (isFirstStep) {
              if (shouldShowPreviewPage()) {
                setShowPreviewPage(true);
              } else {
                backToRateCardList();
              }
            } else {
              navigate(`${urlPrefix}/`);
            }
          }}
          text="Back"
          theme="secondary"
        />
      }
      footerTrailingButtons={[
        <Button
          onClick={async () => {
            if (isLastStep) {
              await onSubmit();
            } else {
              if (isFirstStep && hasDimensionalPricingRates) {
                navigate(`${urlPrefix}/dimensional-pricing`);
              } else {
                navigate(`${urlPrefix}/rate-products`);
              }
            }
          }}
          text={isLastStep ? "Save" : "Continue"}
          theme="primary"
          disabled={nextStepDisabled}
          loading={isLastStep ? createRateCardResult.loading : false}
        />,
      ]}
      children={
        <div className="pb-32">
          {/* Display the current step */}
          <Outlet />
          {showRateCreateModal && (
            <RateCardCreatedModal
              onClose={() => {
                setShowRateCreateModal(false);
                pushMessage({
                  type: "success",
                  content: "Rate card created successfully",
                });
                clearSnapshot();
                navigate(
                  newUIEnabled
                    ? `/offering/rate-cards`
                    : `/contract-pricing/rate-cards`,
                );
              }}
            />
          )}
          {showPreviewPage ? (
            <RateCardCreateLandingPage
              setShowPreviewPage={setShowPreviewPage}
            />
          ) : null}
        </div>
      }
    ></Takeover>
  );
};
export const RateCardCreateV2: React.FC = () => {
  return (
    <RateCardContext.Provider>
      <RateCardCreate />
    </RateCardContext.Provider>
  );
};
