/* 7e4ecba65e7196ee5df5a51d6eba1f5fed680333
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductCustomFieldsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type ProductCustomFieldsQuery = { __typename?: 'Query', Product: Array<{ __typename?: 'Product', id: string, name: string, ProductManagedFields: Array<{ __typename?: 'ProductManagedField', id: string, value: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> }> };

export type CustomerCustomFieldsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type CustomerCustomFieldsQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, name: string, CustomerManagedFields: Array<{ __typename?: 'CustomerManagedField', id: string, value: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> }> };

export type CreditGrantCustomFieldsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type CreditGrantCustomFieldsQuery = { __typename?: 'Query', CreditGrant: Array<{ __typename?: 'CreditGrant', id: string, name: string, CreditGrantManagedFields: Array<{ __typename?: 'CreditGrantManagedField', id: string, value: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> }> };

export type ChargeCustomFieldsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type ChargeCustomFieldsQuery = { __typename?: 'Query', Product: Array<{ __typename?: 'Product', id: string, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, ChargeManagedFields: Array<{ __typename?: 'ChargeManagedField', id: string, value: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> }> }> };


export const ProductCustomFieldsDocument = gql`
    query ProductCustomFields($limit: Int!, $cursor: uuid) {
  Product(order_by: {id: asc}, limit: $limit, where: {id: {_gt: $cursor}}) {
    id
    name
    ProductManagedFields {
      id
      value
      ManagedFieldKey {
        id
        key
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductCustomFieldsQuery__
 *
 * To run a query within a React component, call `useProductCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCustomFieldsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useProductCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>(ProductCustomFieldsDocument, options);
      }
export function useProductCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>(ProductCustomFieldsDocument, options);
        }
export type ProductCustomFieldsQueryHookResult = ReturnType<typeof useProductCustomFieldsQuery>;
export type ProductCustomFieldsLazyQueryHookResult = ReturnType<typeof useProductCustomFieldsLazyQuery>;
export type ProductCustomFieldsQueryResult = Apollo.QueryResult<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>;
export const CustomerCustomFieldsDocument = gql`
    query CustomerCustomFields($limit: Int!, $cursor: uuid) {
  Customer(order_by: {id: asc}, limit: $limit, where: {id: {_gt: $cursor}}) {
    id
    name
    CustomerManagedFields {
      id
      value
      ManagedFieldKey {
        id
        key
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerCustomFieldsQuery__
 *
 * To run a query within a React component, call `useCustomerCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCustomFieldsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCustomerCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<CustomerCustomFieldsQuery, CustomerCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCustomFieldsQuery, CustomerCustomFieldsQueryVariables>(CustomerCustomFieldsDocument, options);
      }
export function useCustomerCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCustomFieldsQuery, CustomerCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCustomFieldsQuery, CustomerCustomFieldsQueryVariables>(CustomerCustomFieldsDocument, options);
        }
export type CustomerCustomFieldsQueryHookResult = ReturnType<typeof useCustomerCustomFieldsQuery>;
export type CustomerCustomFieldsLazyQueryHookResult = ReturnType<typeof useCustomerCustomFieldsLazyQuery>;
export type CustomerCustomFieldsQueryResult = Apollo.QueryResult<CustomerCustomFieldsQuery, CustomerCustomFieldsQueryVariables>;
export const CreditGrantCustomFieldsDocument = gql`
    query CreditGrantCustomFields($limit: Int!, $cursor: uuid) {
  CreditGrant(order_by: {id: asc}, limit: $limit, where: {id: {_gt: $cursor}}) {
    id
    name
    CreditGrantManagedFields {
      id
      value
      ManagedFieldKey {
        id
        key
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCreditGrantCustomFieldsQuery__
 *
 * To run a query within a React component, call `useCreditGrantCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditGrantCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditGrantCustomFieldsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCreditGrantCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<CreditGrantCustomFieldsQuery, CreditGrantCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditGrantCustomFieldsQuery, CreditGrantCustomFieldsQueryVariables>(CreditGrantCustomFieldsDocument, options);
      }
export function useCreditGrantCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditGrantCustomFieldsQuery, CreditGrantCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditGrantCustomFieldsQuery, CreditGrantCustomFieldsQueryVariables>(CreditGrantCustomFieldsDocument, options);
        }
export type CreditGrantCustomFieldsQueryHookResult = ReturnType<typeof useCreditGrantCustomFieldsQuery>;
export type CreditGrantCustomFieldsLazyQueryHookResult = ReturnType<typeof useCreditGrantCustomFieldsLazyQuery>;
export type CreditGrantCustomFieldsQueryResult = Apollo.QueryResult<CreditGrantCustomFieldsQuery, CreditGrantCustomFieldsQueryVariables>;
export const ChargeCustomFieldsDocument = gql`
    query ChargeCustomFields($limit: Int!, $cursor: uuid) {
  Product(order_by: {id: asc}, limit: $limit, where: {id: {_gt: $cursor}}) {
    id
    ProductPricingFactors {
      id
      name
      ChargeManagedFields {
        id
        value
        ManagedFieldKey {
          id
          key
          __environment_type: environment_type
        }
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useChargeCustomFieldsQuery__
 *
 * To run a query within a React component, call `useChargeCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCustomFieldsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useChargeCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<ChargeCustomFieldsQuery, ChargeCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargeCustomFieldsQuery, ChargeCustomFieldsQueryVariables>(ChargeCustomFieldsDocument, options);
      }
export function useChargeCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargeCustomFieldsQuery, ChargeCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargeCustomFieldsQuery, ChargeCustomFieldsQueryVariables>(ChargeCustomFieldsDocument, options);
        }
export type ChargeCustomFieldsQueryHookResult = ReturnType<typeof useChargeCustomFieldsQuery>;
export type ChargeCustomFieldsLazyQueryHookResult = ReturnType<typeof useChargeCustomFieldsLazyQuery>;
export type ChargeCustomFieldsQueryResult = Apollo.QueryResult<ChargeCustomFieldsQuery, ChargeCustomFieldsQueryVariables>;