/* f4e572e2f628110aca70d5c535df85ec4f1e39c1
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerSettingsInfoQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  date?: Types.InputMaybe<Types.Scalars['timestamptz']>;
}>;


export type GetCustomerSettingsInfoQuery = { __typename?: 'Query', Customer: { __typename?: 'Customer', id: string, name: string, archived_at: string | null, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }>, active_customer_plans: Array<{ __typename?: 'CustomerPlan', id: string }> } | null };

export type UpdateIngestAliasesMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  ingest_aliases: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type UpdateIngestAliasesMutation = { __typename?: 'Mutation', set_customer_ingest_aliases: { __typename?: 'Customer', id: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }> } };


export const GetCustomerSettingsInfoDocument = gql`
    query GetCustomerSettingsInfo($customer_id: uuid!, $date: timestamptz) {
  Customer: Customer_by_pk(id: $customer_id) {
    id
    name
    CustomerIngestAliases {
      ingest_alias
      __environment_type: environment_type
    }
    archived_at
    active_customer_plans: CustomerPlans(
      where: {deleted_at: {_is_null: true}, start_date: {_lte: $date}, _or: [{cancellation_date: {_is_null: true}}, {cancellation_date: {_gte: $date}}]}
    ) {
      id
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomerSettingsInfoQuery__
 *
 * To run a query within a React component, call `useGetCustomerSettingsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerSettingsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerSettingsInfoQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCustomerSettingsInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerSettingsInfoQuery, GetCustomerSettingsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerSettingsInfoQuery, GetCustomerSettingsInfoQueryVariables>(GetCustomerSettingsInfoDocument, options);
      }
export function useGetCustomerSettingsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerSettingsInfoQuery, GetCustomerSettingsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerSettingsInfoQuery, GetCustomerSettingsInfoQueryVariables>(GetCustomerSettingsInfoDocument, options);
        }
export type GetCustomerSettingsInfoQueryHookResult = ReturnType<typeof useGetCustomerSettingsInfoQuery>;
export type GetCustomerSettingsInfoLazyQueryHookResult = ReturnType<typeof useGetCustomerSettingsInfoLazyQuery>;
export type GetCustomerSettingsInfoQueryResult = Apollo.QueryResult<GetCustomerSettingsInfoQuery, GetCustomerSettingsInfoQueryVariables>;
export const UpdateIngestAliasesDocument = gql`
    mutation UpdateIngestAliases($customer_id: uuid!, $ingest_aliases: [String!]!) {
  set_customer_ingest_aliases(
    customer_id: $customer_id
    ingest_aliases: $ingest_aliases
  ) {
    id
    CustomerIngestAliases {
      ingest_alias
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;
export type UpdateIngestAliasesMutationFn = Apollo.MutationFunction<UpdateIngestAliasesMutation, UpdateIngestAliasesMutationVariables>;

/**
 * __useUpdateIngestAliasesMutation__
 *
 * To run a mutation, you first call `useUpdateIngestAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngestAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngestAliasesMutation, { data, loading, error }] = useUpdateIngestAliasesMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      ingest_aliases: // value for 'ingest_aliases'
 *   },
 * });
 */
export function useUpdateIngestAliasesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIngestAliasesMutation, UpdateIngestAliasesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIngestAliasesMutation, UpdateIngestAliasesMutationVariables>(UpdateIngestAliasesDocument, options);
      }
export type UpdateIngestAliasesMutationHookResult = ReturnType<typeof useUpdateIngestAliasesMutation>;
export type UpdateIngestAliasesMutationResult = Apollo.MutationResult<UpdateIngestAliasesMutation>;
export type UpdateIngestAliasesMutationOptions = Apollo.BaseMutationOptions<UpdateIngestAliasesMutation, UpdateIngestAliasesMutationVariables>;