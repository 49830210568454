import React, { useCallback, useEffect, useRef, useState } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { IconName, Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
// import { Dropdown, DropdownGroup } from "design-system";
import useDebounce from "lib/debounce";
import { Button, ButtonProps } from "../Button";
import { IconButton } from "../IconButton";

const SIZE_VARIANTS = {
  sm: "h-[36px] py-sm, px-lg",
  md: "h-[40px] py-md px-lg",
  lg: "h-[44px] py-[10px] px-[14px]",
};

type IconInput = {
  variant: "icon";
  /** Add an icon from the library to lead next on the Input */
  icon: IconName;
};

// type DropdownInput = {
//   variant: "dropdown";
//   /** Provide a trailing dropdown for the Input that will add more context for a field */
//   dropdownOptions: DropdownGroup[];
//   icon?: never;
//   button?: never;
// };

type ButtonInput<T> = {
  variant: "button";
  /**
   * Button will render at the end of the input, appearing to be one whole field.
   */
  button: Omit<ButtonProps, "onClick"> & {
    onClick: (value: T) => void;
  };
};

type VariantOptions<T> = IconInput | ButtonInput<T>;

export type DefaultInput<T> = {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /**
   * When a user provides incorrect or incomplete information in this input, set this to true.
   * Consider updating the hintText as well with context as to why the input failed.
   * */
  isInvalid?: boolean;
  /**
   * Disable the input from user interactions
   */
  disabled?: boolean;
  /**
   * Help text will render a help icon on the end of the input, with a tooltip.
   * Provide help text when you need to give user additional context, and may not fit
   * as `hintText` below the Input.
   * */
  helpText?: string;
  /**
   * Hint text is a short piece of copy to render below the input as string.
   * If there is an error, provide error message here.
   */
  hintText?: string;
  /** Providing this will render a label element associated with the Input */
  label?: string;
  /** Provide a callback to receive input changes if the input field has lost focus. */
  onBlur?: (meta: { value: T }) => void;
  /** Provide a callback to receive debounced input changes if the text is being updated by the user. */
  onChange?: (meta: { value: T }) => void;
  /** Provide a callback to receive key stroke and input value when a user interacts with the input field */
  onKeyDown?: (meta: { key: string; value: T }) => void;
  /** Placeholder for the input. Add text to give a user guidance on what to add for the input. */
  placeholder?: string;
  /** Default: md - sm: 36px, md: 40px, lg: 44px */
  size?: "sm" | "md" | "lg";
  /** Pass a value as a prop to the input */
  value?: T;
  /**
   * Enhancements for the Input component:
   * Icon - renders a leading icon inside the Input
   * Button - renders a trailing button outside the Input
   * */
  variantOptions?: VariantOptions<T>;
  /** Set the input to full width */
  fullWidth?: boolean;
};

type TextInput = DefaultInput<string> & {
  /** Input type, which may result in different aria-roles and input options */
  type?: "password" | "search" | "text";
};

/**
 * PARTIAL IMPLEMENTATION !!
 * - Numbers will be handled by a separate component
 * - Dropdown has not been implemented yet.
 *
 * Input fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * This input also provides options for adding a button to the end, tags inside,
 * or an icon at the start.
 * */
export function TextInput({
  className,
  isInvalid,
  placeholder,
  helpText,
  hintText,
  disabled,
  label,
  size = "md",
  type,
  variantOptions,
  onBlur,
  onChange,
  onKeyDown,
  value,
  fullWidth = false,
}: TextInput) {
  const [inputValue, setInputValue] = useState<string>(value ?? "");
  const debouncedInputValue = useDebounce(inputValue, 400);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange({ value: debouncedInputValue });
    }
  }, [debouncedInputValue, onChange]);

  const handleOnBlur = useCallback(
    (value: string) => {
      if (onBlur) {
        onBlur({ value: value });
        setInputValue("");
      }
    },
    [onBlur, debouncedInputValue],
  );

  const classnames = twMerge(
    "border border-gray-200 bg-white rounded-md text-md shadow-xs flex items-center flex-1",
    SIZE_VARIANTS[size],
    disabled && "bg-gray-50",
    variantOptions?.variant === "button" && "rounded-r-none",
    className,
  );

  return (
    <div>
      {label && (
        <label className="mb-sm text-sm text-black" htmlFor={label}>
          {label}
        </label>
      )}
      <div
        className={twMerge(
          "focus-within:ring-core-jade-green/[.24] focus-within:rounded-md flex focus-within:ring-4",
          isInvalid && "focus-within:ring-error-600/[.24]",
          !fullWidth && "max-w-[400px]",
        )}
      >
        <div onClick={() => inputRef?.current?.focus()} className={classnames}>
          {variantOptions?.variant === "icon" && variantOptions?.icon && (
            <Icon className="mr-lg" icon={variantOptions?.icon} size={20} />
          )}
          <div className="grid-cols-[0 min-content] inline-grid flex-auto">
            <input
              id={label}
              ref={inputRef}
              className="h-full w-full text-black placeholder:text-gray-500 focus-within:outline-none disabled:bg-gray-50"
              placeholder={placeholder}
              disabled={disabled}
              value={inputValue}
              onBlur={(e) => handleOnBlur(e.currentTarget.value)}
              onChange={(e) => setInputValue(e.currentTarget.value)}
              onKeyDown={(e) =>
                onKeyDown?.({ key: e.key, value: e.currentTarget.value })
              }
              type={type}
            />
          </div>
          {inputValue && !disabled && (
            <IconButton
              icon="xCircle"
              theme="tertiary"
              size="sm"
              onClick={() => setInputValue("")}
              className="h-[32px] w-[32px]"
            />
          )}
          {helpText && (
            <Tooltip label={helpText}>
              <Icon icon="helpCircle" size={15} />
            </Tooltip>
          )}
        </div>
        {variantOptions?.variant === "button" && (
          <div className="inline-flex">
            <Button
              {...variantOptions?.button}
              onClick={() => variantOptions?.button.onClick(inputValue)}
              className="rounded-l-none border-l-0"
            />
          </div>
        )}
      </div>
      {hintText && (
        <div
          className={twMerge(
            "mt-sm text-sm text-gray-600",
            isInvalid && "text-error-600",
          )}
        >
          {hintText}
        </div>
      )}
    </div>
  );
}
