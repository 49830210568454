import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { useEnvironment } from "lib/environmentSwitcher/context";
import { useEventDetailsQuery } from "../../queries.graphql";
import { Caption, Select, Subtitle } from "design-system";
import { TextSkeleton } from "components/Skeleton";
import { EmptyState } from "components/EmptyState";
import { useNavigate, useLocation } from "react-router-dom";
import { CodeBlock } from "components/CodeBlock";
import { EventTimelineTip } from "../EventTimelineTips";
import { Summary } from "../EventTimelineSummary";
import { EventTimeline } from "../EventTimeline";

export type EventDetailRef = {
  refetch: () => void;
};

type EventDetailProps = {
  eventID?: string;
  transactionIDs: string[];
  startingAfter: Date;
  endingBefore: Date;
  ingest_aliases?: string[];
  duplicates?: boolean;
  billableMetricIDs?: string[];
  ref?: React.Ref<EventDetailRef>;
};

export const EventDetail: React.FC<EventDetailProps> = forwardRef(
  (props: EventDetailProps, ref) => {
    const { environmentType } = useEnvironment();
    const location = useLocation();
    const navigate = useNavigate();

    const { data, loading, refetch } = useEventDetailsQuery({
      variables: {
        environment_type: environmentType,

        starting_after: props.startingAfter.toISOString(),
        ending_before: props.endingBefore.toISOString(),
        ingest_aliases: props.ingest_aliases,
        transaction_ids: props.transactionIDs,
        duplicates: props.duplicates,
        billable_metric_ids: props.billableMetricIDs,
      },
    });

    useImperativeHandle(ref, () => ({
      refetch: () => {
        void refetch();
      },
    }));

    useEffect(() => {
      if (props.eventID) {
        return;
      }
      const firstEventID = data?.events?.[0]?.id;
      if (!firstEventID) {
        return;
      }
      const params = new URLSearchParams(window.location.search);
      params.set("eventID", firstEventID);
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [data?.events, props.eventID]);

    const header = (
      <div className="flex items-center justify-between px-0 py-12">
        <div>
          <Subtitle>Event summary</Subtitle>
          <Caption level={2}>
            Transaction ID: {props.transactionIDs.join(", ")}
          </Caption>
        </div>
        {data?.events && data?.events.length > 1 ? (
          <div className="min-w-[300px]">
            <Select
              placeholder="Select event"
              options={data.events.map((event) => ({
                label: `${event.timestamp}${
                  event.is_duplicate ? " (duplicate)" : ""
                }`,
                value: event.id,
              }))}
              value={props.eventID}
              onChange={(v) => {
                const params = new URLSearchParams(window.location.search);
                params.set("eventID", v);
                navigate(`${location.pathname}?${params.toString()}`);
              }}
            />
          </div>
        ) : null}
      </div>
    );

    if (loading) {
      return (
        <div>
          {header}
          <TextSkeleton />
          <TextSkeleton />
        </div>
      );
    }

    const event =
      data?.events.find((event) => event.id === props.eventID) ||
      data?.events[0];

    if ((!loading && data?.events.length === 0) || !event) {
      return (
        <div>
          {header}
          <EmptyState
            title="No events found"
            subtitle="No events that matched the provided filters were found. Try adjusting the filters and or date range."
            icon="searchSm"
          />
        </div>
      );
    }
    return (
      <div>
        {header}
        <div className="flex justify-between gap-12">
          <div>
            <Summary event={event} />
            <EventTimeline event={event} />
            <EventTimelineTip event={event} />
          </div>
          <div>
            <CodeBlock
              language="json"
              code={JSON.stringify(
                {
                  timestamp: event.timestamp,
                  transaction_id: event.transaction_id,
                  customer_id: event.customer_id,
                  event_type: event.event_type,
                  properties: event.properties,
                },
                null,
                2,
              )}
              title="Payload"
              readOnly
            />
          </div>
        </div>
      </div>
    );
  },
);
