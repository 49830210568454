/* 9624e23e289b0f6b4b471fef19445f9648e01095
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuditLogFieldsFragment = { __typename?: 'AuditLog', id: string, request_timestamp: string, description: string | null, resource_id: string, resource_type: string, action: string, status: Types.AuditLogStatusEnum, actor: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | { __typename?: 'User', id: string, name: string, email: string, deprecated_at: string | null } };

export type GetAuditLogsQueryVariables = Types.Exact<{
  starting_after?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  ending_before?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  resource_id?: Types.InputMaybe<Types.Scalars['String']>;
  resource_type?: Types.InputMaybe<Types.Scalars['String']>;
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAuditLogsQuery = { __typename?: 'Query', audit_logs: { __typename?: 'AuditLogResponse', next_cursor: string | null, data: Array<{ __typename?: 'AuditLog', id: string, request_timestamp: string, description: string | null, resource_id: string, resource_type: string, action: string, status: Types.AuditLogStatusEnum, actor: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | { __typename?: 'User', id: string, name: string, email: string, deprecated_at: string | null } }> } };

export type GetAuditLogByIdQueryVariables = Types.Exact<{
  log_id: Types.Scalars['String'];
  requestTimestamp: Types.Scalars['timestamptz'];
}>;


export type GetAuditLogByIdQuery = { __typename?: 'Query', audit_log: { __typename?: 'AuditLog', id: string, request_timestamp: string, description: string | null, resource_id: string, resource_type: string, action: string, status: Types.AuditLogStatusEnum, actor: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | { __typename?: 'User', id: string, name: string, email: string, deprecated_at: string | null } } | null };

export const AuditLogFieldsFragmentDoc = gql`
    fragment AuditLogFields on AuditLog {
  id
  request_timestamp
  description
  resource_id
  resource_type
  action
  status
  actor {
    ... on User {
      id
      name
      email
      deprecated_at
    }
    ... on Actor {
      id
      name
      deprecated_at
    }
  }
}
    `;
export const GetAuditLogsDocument = gql`
    query GetAuditLogs($starting_after: timestamptz, $ending_before: timestamptz, $resource_id: String, $resource_type: String, $limit: Int!, $cursor: String) {
  audit_logs(
    limit: $limit
    descending: true
    starting_after: $starting_after
    ending_before: $ending_before
    resource_id: $resource_id
    resource_type: $resource_type
    cursor: $cursor
  ) {
    data {
      ...AuditLogFields
    }
    next_cursor
  }
}
    ${AuditLogFieldsFragmentDoc}`;

/**
 * __useGetAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogsQuery({
 *   variables: {
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      resource_id: // value for 'resource_id'
 *      resource_type: // value for 'resource_type'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, options);
      }
export function useGetAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, options);
        }
export type GetAuditLogsQueryHookResult = ReturnType<typeof useGetAuditLogsQuery>;
export type GetAuditLogsLazyQueryHookResult = ReturnType<typeof useGetAuditLogsLazyQuery>;
export type GetAuditLogsQueryResult = Apollo.QueryResult<GetAuditLogsQuery, GetAuditLogsQueryVariables>;
export const GetAuditLogByIdDocument = gql`
    query GetAuditLogById($log_id: String!, $requestTimestamp: timestamptz!) {
  audit_log(logID: $log_id, requestTimestamp: $requestTimestamp) {
    ...AuditLogFields
  }
}
    ${AuditLogFieldsFragmentDoc}`;

/**
 * __useGetAuditLogByIdQuery__
 *
 * To run a query within a React component, call `useGetAuditLogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogByIdQuery({
 *   variables: {
 *      log_id: // value for 'log_id'
 *      requestTimestamp: // value for 'requestTimestamp'
 *   },
 * });
 */
export function useGetAuditLogByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAuditLogByIdQuery, GetAuditLogByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditLogByIdQuery, GetAuditLogByIdQueryVariables>(GetAuditLogByIdDocument, options);
      }
export function useGetAuditLogByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditLogByIdQuery, GetAuditLogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditLogByIdQuery, GetAuditLogByIdQueryVariables>(GetAuditLogByIdDocument, options);
        }
export type GetAuditLogByIdQueryHookResult = ReturnType<typeof useGetAuditLogByIdQuery>;
export type GetAuditLogByIdLazyQueryHookResult = ReturnType<typeof useGetAuditLogByIdLazyQuery>;
export type GetAuditLogByIdQueryResult = Apollo.QueryResult<GetAuditLogByIdQuery, GetAuditLogByIdQueryVariables>;