/* 531ff18474d893f799e016d23e83f8ff25d03ed7
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSalesforceCensusProvisionedStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSalesforceCensusProvisionedStateQuery = { __typename?: 'Query', get_census_provisioned_state: { __typename?: 'CensusWorkspaceProvisionedState', id: string, is_provisioned: boolean, source_configured: boolean, destination_configured: boolean, environment_variables: { [key: string]: string | number | boolean } } | null };

export type CreateCensusWorkspaceMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateCensusWorkspaceMutation = { __typename?: 'Mutation', create_census_workspace: { __typename?: 'CensusWorkspace', id: string } };

export type GetSalesforceDestinationLinkMutationVariables = Types.Exact<{
  redirect_uri: Types.Scalars['String'];
}>;


export type GetSalesforceDestinationLinkMutation = { __typename?: 'Mutation', generate_census_destination_connect_link: { __typename?: 'CensusDestinationConnectLink', id: string, uri: string } };

export type ProvisionSalesforceCensusWorkspaceMutationVariables = Types.Exact<{
  only_linked_accounts: Types.Scalars['Boolean'];
}>;


export type ProvisionSalesforceCensusWorkspaceMutation = { __typename?: 'Mutation', provision_salesforce_census_workspace: { __typename?: 'MutationResponse', success: boolean, error: string | null } };


export const GetSalesforceCensusProvisionedStateDocument = gql`
    query getSalesforceCensusProvisionedState {
  get_census_provisioned_state(destination_type: SALESFORCE) {
    id
    is_provisioned
    source_configured
    destination_configured
    environment_variables
  }
}
    `;

/**
 * __useGetSalesforceCensusProvisionedStateQuery__
 *
 * To run a query within a React component, call `useGetSalesforceCensusProvisionedStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceCensusProvisionedStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceCensusProvisionedStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesforceCensusProvisionedStateQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>(GetSalesforceCensusProvisionedStateDocument, options);
      }
export function useGetSalesforceCensusProvisionedStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>(GetSalesforceCensusProvisionedStateDocument, options);
        }
export type GetSalesforceCensusProvisionedStateQueryHookResult = ReturnType<typeof useGetSalesforceCensusProvisionedStateQuery>;
export type GetSalesforceCensusProvisionedStateLazyQueryHookResult = ReturnType<typeof useGetSalesforceCensusProvisionedStateLazyQuery>;
export type GetSalesforceCensusProvisionedStateQueryResult = Apollo.QueryResult<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>;
export const CreateCensusWorkspaceDocument = gql`
    mutation createCensusWorkspace {
  create_census_workspace(destination_type: SALESFORCE) {
    id
    __environment_type: environment_type
  }
}
    `;
export type CreateCensusWorkspaceMutationFn = Apollo.MutationFunction<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>;

/**
 * __useCreateCensusWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateCensusWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCensusWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCensusWorkspaceMutation, { data, loading, error }] = useCreateCensusWorkspaceMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCensusWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>(CreateCensusWorkspaceDocument, options);
      }
export type CreateCensusWorkspaceMutationHookResult = ReturnType<typeof useCreateCensusWorkspaceMutation>;
export type CreateCensusWorkspaceMutationResult = Apollo.MutationResult<CreateCensusWorkspaceMutation>;
export type CreateCensusWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>;
export const GetSalesforceDestinationLinkDocument = gql`
    mutation getSalesforceDestinationLink($redirect_uri: String!) {
  generate_census_destination_connect_link(
    destination_type: SALESFORCE
    redirect_uri: $redirect_uri
  ) {
    id
    uri
  }
}
    `;
export type GetSalesforceDestinationLinkMutationFn = Apollo.MutationFunction<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>;

/**
 * __useGetSalesforceDestinationLinkMutation__
 *
 * To run a mutation, you first call `useGetSalesforceDestinationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceDestinationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSalesforceDestinationLinkMutation, { data, loading, error }] = useGetSalesforceDestinationLinkMutation({
 *   variables: {
 *      redirect_uri: // value for 'redirect_uri'
 *   },
 * });
 */
export function useGetSalesforceDestinationLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>(GetSalesforceDestinationLinkDocument, options);
      }
export type GetSalesforceDestinationLinkMutationHookResult = ReturnType<typeof useGetSalesforceDestinationLinkMutation>;
export type GetSalesforceDestinationLinkMutationResult = Apollo.MutationResult<GetSalesforceDestinationLinkMutation>;
export type GetSalesforceDestinationLinkMutationOptions = Apollo.BaseMutationOptions<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>;
export const ProvisionSalesforceCensusWorkspaceDocument = gql`
    mutation provisionSalesforceCensusWorkspace($only_linked_accounts: Boolean!) {
  provision_salesforce_census_workspace(
    only_linked_accounts: $only_linked_accounts
  ) {
    success
    error
  }
}
    `;
export type ProvisionSalesforceCensusWorkspaceMutationFn = Apollo.MutationFunction<ProvisionSalesforceCensusWorkspaceMutation, ProvisionSalesforceCensusWorkspaceMutationVariables>;

/**
 * __useProvisionSalesforceCensusWorkspaceMutation__
 *
 * To run a mutation, you first call `useProvisionSalesforceCensusWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionSalesforceCensusWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionSalesforceCensusWorkspaceMutation, { data, loading, error }] = useProvisionSalesforceCensusWorkspaceMutation({
 *   variables: {
 *      only_linked_accounts: // value for 'only_linked_accounts'
 *   },
 * });
 */
export function useProvisionSalesforceCensusWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<ProvisionSalesforceCensusWorkspaceMutation, ProvisionSalesforceCensusWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvisionSalesforceCensusWorkspaceMutation, ProvisionSalesforceCensusWorkspaceMutationVariables>(ProvisionSalesforceCensusWorkspaceDocument, options);
      }
export type ProvisionSalesforceCensusWorkspaceMutationHookResult = ReturnType<typeof useProvisionSalesforceCensusWorkspaceMutation>;
export type ProvisionSalesforceCensusWorkspaceMutationResult = Apollo.MutationResult<ProvisionSalesforceCensusWorkspaceMutation>;
export type ProvisionSalesforceCensusWorkspaceMutationOptions = Apollo.BaseMutationOptions<ProvisionSalesforceCensusWorkspaceMutation, ProvisionSalesforceCensusWorkspaceMutationVariables>;