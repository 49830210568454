/* f30c60d581541fcc7e0842fd13cfa0369ef80744
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductOrderQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
}>;


export type GetProductOrderQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, products: Array<{ __typename?: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }> } } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

export type MoveRateCardProductsMutationVariables = Types.Exact<{
  input: Types.MoveRateCardProductsInput;
}>;


export type MoveRateCardProductsMutation = { __typename?: 'Mutation', move_rate_card_products: { __typename?: 'RateCard', id: string } };

export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const GetProductOrderDocument = gql`
    query GetProductOrder($rateCardId: uuid!) {
  contract_pricing {
    rate_card(id: $rateCardId) {
      id
      products {
        ...ProductListItemId
        ...ProductListItemName
      }
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}`;

/**
 * __useGetProductOrderQuery__
 *
 * To run a query within a React component, call `useGetProductOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOrderQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *   },
 * });
 */
export function useGetProductOrderQuery(baseOptions: Apollo.QueryHookOptions<GetProductOrderQuery, GetProductOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductOrderQuery, GetProductOrderQueryVariables>(GetProductOrderDocument, options);
      }
export function useGetProductOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductOrderQuery, GetProductOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductOrderQuery, GetProductOrderQueryVariables>(GetProductOrderDocument, options);
        }
export type GetProductOrderQueryHookResult = ReturnType<typeof useGetProductOrderQuery>;
export type GetProductOrderLazyQueryHookResult = ReturnType<typeof useGetProductOrderLazyQuery>;
export type GetProductOrderQueryResult = Apollo.QueryResult<GetProductOrderQuery, GetProductOrderQueryVariables>;
export const MoveRateCardProductsDocument = gql`
    mutation MoveRateCardProducts($input: MoveRateCardProductsInput!) {
  move_rate_card_products(input: $input) {
    id
  }
}
    `;
export type MoveRateCardProductsMutationFn = Apollo.MutationFunction<MoveRateCardProductsMutation, MoveRateCardProductsMutationVariables>;

/**
 * __useMoveRateCardProductsMutation__
 *
 * To run a mutation, you first call `useMoveRateCardProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRateCardProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRateCardProductsMutation, { data, loading, error }] = useMoveRateCardProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveRateCardProductsMutation(baseOptions?: Apollo.MutationHookOptions<MoveRateCardProductsMutation, MoveRateCardProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveRateCardProductsMutation, MoveRateCardProductsMutationVariables>(MoveRateCardProductsDocument, options);
      }
export type MoveRateCardProductsMutationHookResult = ReturnType<typeof useMoveRateCardProductsMutation>;
export type MoveRateCardProductsMutationResult = Apollo.MutationResult<MoveRateCardProductsMutation>;
export type MoveRateCardProductsMutationOptions = Apollo.BaseMutationOptions<MoveRateCardProductsMutation, MoveRateCardProductsMutationVariables>;