/* d1c6bf9c9638f4fcf36179b2f5cfc04ca50afca7
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokensQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type TokensQuery = { __typename?: 'Query', Tokens: Array<{ __typename?: 'Tokens', created_at: string, name: string, id: string, token_display_suffix: string, deprecated_at: string | null }> };

export type InsertTokenMutationVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  name: Types.Scalars['String'];
}>;


export type InsertTokenMutation = { __typename?: 'Mutation', create_api_token: { __typename?: 'APITokenResponse', id: string, token_string: string } };

export type DeleteTokenMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeleteTokenMutation = { __typename?: 'Mutation', delete_Tokens: { __typename?: 'Tokens_mutation_response', affected_rows: number } | null };


export const TokensDocument = gql`
    query Tokens($environment_type: EnvironmentTypeEnum_enum!) {
  Tokens(where: {environment_type: {_eq: $environment_type}}) {
    created_at
    name
    id
    token_display_suffix
    deprecated_at
    __environment_type: environment_type
  }
}
    `;

/**
 * __useTokensQuery__
 *
 * To run a query within a React component, call `useTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useTokensQuery(baseOptions: Apollo.QueryHookOptions<TokensQuery, TokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
      }
export function useTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokensQuery, TokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
        }
export type TokensQueryHookResult = ReturnType<typeof useTokensQuery>;
export type TokensLazyQueryHookResult = ReturnType<typeof useTokensLazyQuery>;
export type TokensQueryResult = Apollo.QueryResult<TokensQuery, TokensQueryVariables>;
export const InsertTokenDocument = gql`
    mutation InsertToken($environment_type: EnvironmentTypeEnum_enum!, $name: String!) {
  create_api_token(environment_type: $environment_type, name: $name) {
    id
    token_string
  }
}
    `;
export type InsertTokenMutationFn = Apollo.MutationFunction<InsertTokenMutation, InsertTokenMutationVariables>;

/**
 * __useInsertTokenMutation__
 *
 * To run a mutation, you first call `useInsertTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTokenMutation, { data, loading, error }] = useInsertTokenMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertTokenMutation(baseOptions?: Apollo.MutationHookOptions<InsertTokenMutation, InsertTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTokenMutation, InsertTokenMutationVariables>(InsertTokenDocument, options);
      }
export type InsertTokenMutationHookResult = ReturnType<typeof useInsertTokenMutation>;
export type InsertTokenMutationResult = Apollo.MutationResult<InsertTokenMutation>;
export type InsertTokenMutationOptions = Apollo.BaseMutationOptions<InsertTokenMutation, InsertTokenMutationVariables>;
export const DeleteTokenDocument = gql`
    mutation DeleteToken($id: String) {
  delete_Tokens(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteTokenMutationFn = Apollo.MutationFunction<DeleteTokenMutation, DeleteTokenMutationVariables>;

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTokenMutation, DeleteTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(DeleteTokenDocument, options);
      }
export type DeleteTokenMutationHookResult = ReturnType<typeof useDeleteTokenMutation>;
export type DeleteTokenMutationResult = Apollo.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = Apollo.BaseMutationOptions<DeleteTokenMutation, DeleteTokenMutationVariables>;