/* 13b643ec191230e91431b33ab53553f844554759
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteNetSuiteCustomerIdMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type DeleteNetSuiteCustomerIdMutation = { __typename?: 'Mutation', delete_CustomerConfig: { __typename?: 'CustomerConfig_mutation_response', affected_rows: number } | null };


export const DeleteNetSuiteCustomerIdDocument = gql`
    mutation DeleteNetSuiteCustomerId($customer_id: uuid!) {
  delete_CustomerConfig(
    where: {customer_id: {_eq: $customer_id}, key: {_eq: "netsuite_customer_id"}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteNetSuiteCustomerIdMutationFn = Apollo.MutationFunction<DeleteNetSuiteCustomerIdMutation, DeleteNetSuiteCustomerIdMutationVariables>;

/**
 * __useDeleteNetSuiteCustomerIdMutation__
 *
 * To run a mutation, you first call `useDeleteNetSuiteCustomerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNetSuiteCustomerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNetSuiteCustomerIdMutation, { data, loading, error }] = useDeleteNetSuiteCustomerIdMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useDeleteNetSuiteCustomerIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNetSuiteCustomerIdMutation, DeleteNetSuiteCustomerIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNetSuiteCustomerIdMutation, DeleteNetSuiteCustomerIdMutationVariables>(DeleteNetSuiteCustomerIdDocument, options);
      }
export type DeleteNetSuiteCustomerIdMutationHookResult = ReturnType<typeof useDeleteNetSuiteCustomerIdMutation>;
export type DeleteNetSuiteCustomerIdMutationResult = Apollo.MutationResult<DeleteNetSuiteCustomerIdMutation>;
export type DeleteNetSuiteCustomerIdMutationOptions = Apollo.BaseMutationOptions<DeleteNetSuiteCustomerIdMutation, DeleteNetSuiteCustomerIdMutationVariables>;