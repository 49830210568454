/* 281a54f1e7297b49610d998ef9e0679d5ae67192
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RateCardProductCountQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
}>;


export type RateCardProductCountQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, product_count: number } } };


export const RateCardProductCountDocument = gql`
    query RateCardProductCount($rateCardId: uuid!) {
  contract_pricing {
    rate_card(id: $rateCardId) {
      id
      product_count
    }
  }
}
    `;

/**
 * __useRateCardProductCountQuery__
 *
 * To run a query within a React component, call `useRateCardProductCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardProductCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardProductCountQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *   },
 * });
 */
export function useRateCardProductCountQuery(baseOptions: Apollo.QueryHookOptions<RateCardProductCountQuery, RateCardProductCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateCardProductCountQuery, RateCardProductCountQueryVariables>(RateCardProductCountDocument, options);
      }
export function useRateCardProductCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateCardProductCountQuery, RateCardProductCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateCardProductCountQuery, RateCardProductCountQueryVariables>(RateCardProductCountDocument, options);
        }
export type RateCardProductCountQueryHookResult = ReturnType<typeof useRateCardProductCountQuery>;
export type RateCardProductCountLazyQueryHookResult = ReturnType<typeof useRateCardProductCountLazyQuery>;
export type RateCardProductCountQueryResult = Apollo.QueryResult<RateCardProductCountQuery, RateCardProductCountQueryVariables>;